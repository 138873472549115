import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { motion } from 'framer-motion';
import { addressSelector, contactDetailsSelector, useMedicalDebtQuizStore } from '../../store';
import { useSaveMedicalFile } from '../../../../hooks/api/useTritonHubspotApi';
import { fadeInLeft } from '../../../../helpers/utils/animations';
import { ROUTES } from '../../../../constants/constants';
import FilePicker from '../../../FilePicker/FilePicker';
import Container from '../../../Container';
import Typography from '../../../Typography';
import Button from '../../../ui/Button';
import IconArrowRight from '../../../ui/Icons/IconArrowRight';
import s from './MedicalDebtQuizHospitalBillStep.module.css';

const MedicalDebtQuizHospitalBillStep = () => {
    const navigate = useNavigate();

    const { email } = useMedicalDebtQuizStore(useShallow(contactDetailsSelector));
    const { city } = useMedicalDebtQuizStore(useShallow(addressSelector));

    const [file, setFile] = useState(null);

    const { mutate: saveMedicalFile, isLoading: isSavingMedicalFile } = useSaveMedicalFile();

    const prevClickHandler = useCallback(() => {
        navigate(`../${ROUTES.medicalDebtQuizAddress}`);
    }, [navigate]);

    const nextClickHandler = useCallback(() => {
        saveMedicalFile(
            {
                file,
                email,
            },
            {
                onSuccess() {
                    navigate(`../${ROUTES.reviewOptions}`);
                },
            }
        );
    }, [email, file, navigate, saveMedicalFile]);

    const skipClickHandler = useCallback(() => {
        setFile(null);
        navigate(`../${ROUTES.reviewOptions}`);
    }, [navigate, setFile]);

    const fileChangeHandler = useCallback((file) => {
        setFile(file);
    }, []);

    useEffect(() => {
        if (!city) {
            navigate(`../${ROUTES.medicalDebtQuizAddress}`, {
                replace: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [city]);

    return (
        <Container>
            <motion.section className={s.wrap} custom={1} variants={fadeInLeft}>
                <Typography className={s.title} variant="heading-h2" asChild>
                    <h3>
                        Do you have a copy of your <strong>hospital bill</strong>?
                    </h3>
                </Typography>
                <motion.div className={s['options-wrap']} variants={fadeInLeft}>
                    <FilePicker
                        label="Upload your file"
                        placeholder="example.csv"
                        fileName={file?.name}
                        onFileChange={fileChangeHandler}
                    />
                </motion.div>
                <footer className={s.footer}>
                    <Button
                        className={s.cta}
                        onClick={prevClickHandler}
                        variant="sixth"
                        responsive
                        disabled={isSavingMedicalFile}
                    >
                        Back
                    </Button>
                    {file ? (
                        <Button
                            className={s.cta}
                            onClick={nextClickHandler}
                            Icon={IconArrowRight}
                            disabled={!file}
                            responsive
                        >
                            Next
                        </Button>
                    ) : (
                        <Button
                            className={s.cta}
                            onClick={skipClickHandler}
                            Icon={IconArrowRight}
                            responsive
                            disabled={isSavingMedicalFile}
                        >
                            Skip
                        </Button>
                    )}
                </footer>
            </motion.section>
        </Container>
    );
};
export default memo(MedicalDebtQuizHospitalBillStep);
