export const HOME_DOUBLE_SECTIONS_ITEMS = [
    {
        image: './images/svg/personal-loan.svg',
        imageMob: './images/svg/personal-loan-mob.svg',
        title: 'Personal Loans',
        titleBold: 'Loans',
        titleRest: 'Personal',
        text: 'Consolidate burdensome loans and start making significant progress towards your payoff.',
        follow: 'button',
        imagePosition: 'left',
    },
    {
        image: './images/svg/debt-card.svg',
        imageMob: './images/svg/debt-card-mob.svg',
        title: 'Credit Cards',
        titleBold: 'Cards',
        titleRest: 'Credit',
        text: 'Learn how you can combine your debts into one monthly payment to lower your interest, save monthly, and shorten your payoff time',
        follow: 'button',
        imagePosition: 'right',
    },
    {
        image: './images/svg/lines-of-credit.svg',
        imageMob: './images/svg/lines-of-credit-mob.svg',
        title: 'Medical Debt',
        titleBold: 'Debt',
        titleRest: 'Medical',
        text: 'Learn what rights you have to free or discounted health care and let us negotiate your existing medical debt without impacting your credit score. ',
        follow: 'button',
        imagePosition: 'left',
    },
];

export const HOME_PAGE_REVIEWS = [
    {
        id: 's4',
        rating: 5,
        text: '"Customer service was very responsive and helped me straighten out a problem..."',
        name: 'Marine',
        shortName: 'M',
        date: '3 hours ago',
    },
    {
        id: 's5',
        rating: 5,
        text: '"After many years of paying off student loans and being extremely tight with money..."',
        name: 'Jack',
        shortName: 'J',
        date: '1 day ago',
        customColor: '#DF8B0E',
        customBg: 'rgba(223, 139, 14, 0.1)',
    },
    {
        id: 's6',
        rating: 5,
        text: '“I`m very thankful that I was able to get the loan. Looking forward to recommend Triton”',
        name: 'Anna',
        shortName: 'A',
        date: '2 hours ago',
        customColor: '#98DB96',
        customBg: 'rgba(152, 219, 150, 0.1)',
    },
];

export const CARDS = [
    {
        image: './images/fill.svg',
        title: 'Fill out our form',
        desc: 'Our brief questionnaire analyzes your needs and instantly matches you with the best available options, without affecting your score',
    },
    {
        image: './images/select.svg',
        title: 'Select appropriate options',
        desc: 'We analyze your questionnaire and match you with the best consolidation options that save you time and money',
    },
    {
        image: './images/solution.svg',
        title: 'Get solution options',
        desc: 'Review your options then select the best consolidation to get approved',
    },
];
