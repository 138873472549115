export const FOOTER_NAV = [
    // {
    //     name: 'About Us',
    //     to: '/about',
    // },
    // {
    //     name: 'How We Help',
    //     to: '/help',
    // },
    // {
    //     name: 'Blog',
    //     to: '/blog',
    // },
    {
        name: 'Privacy Policy',
        to: '/policy',
    },
    {
        name: 'Terms of Use',
        to: '/terms',
    },
];
