import { tritonHubspotHttp } from './axios';

/**
 *
 * @param {{
 *  file: File,
 *  email: string,
 * }} data
 */
export const saveMedicalFile = async (data) => {
    const response = await tritonHubspotHttp.postForm('/save-medical-file', data);

    return response.data;
};
