import { memo, useCallback } from 'react';
import { useShallow } from 'zustand/react/shallow';
import clsx from 'clsx';
import { contactDetailsSelector, useMedicalDebtQuizStore } from '../../store';
import QuizResultTitle from '../../../Quiz/components/QuizReviewOptionsStep/components/QuizResultTitle';
import Button from '../../../ui/Button';
import IconArrowRight from '../../../ui/Icons/IconArrowRight';
import s from './MedicalDebtQuizQualifiedStep.module.css';
import { STRIPE_MEDICAL_DEBT_PAYMENT_URL } from '../../../../constants/constants';

const MedicalDebtQuizQualifiedStep = () => {
    const { firstName, email } = useMedicalDebtQuizStore(useShallow(contactDetailsSelector));

    const stripePaymentClickHandler = useCallback(() => {
        window.open(`${STRIPE_MEDICAL_DEBT_PAYMENT_URL}?prefilled_email=${email}`, '_blank');
    }, [email]);

    return (
        <section className={s.wrap}>
            <QuizResultTitle
                className={s['result-title']}
                title={<>Congratulations {firstName}</>}
                subtitle="You are qualified for Careguide Advocates' Hospital Bill Eraser Program."
                description={<>Please navigate to the next page to complete the registration for the program.</>}
            />

            <footer className={s.footer}>
                <Button
                    className={clsx(s.cta, s.home)}
                    Icon={IconArrowRight}
                    responsive
                    onClick={stripePaymentClickHandler}
                >
                    Get Started
                </Button>
            </footer>
        </section>
    );
};

export default memo(MedicalDebtQuizQualifiedStep);
