import { useContext } from 'react';
import ArticleSection from '../../components/Article/ArticleSection';
import CalculatorSection from '../../components/CalculatorSection/CalculatorSection';
import DoubleSection from '../../components/DoubleSection/DoubleSection';
import TopContentBlock from '../../components/TopContentBlock/TopContentBlock';
import { AppContext } from '../../App';
import { ROUTES } from '../../constants/constants';
import { ABOUT_DOUBLE_SECTIONS_ITEMS } from './constants';
import WhoWeAre from '../../components/WhoWeAre/WhoWeAre';
import History from '../../components/History/History';
import Partners from './components/Partners';
import s from './AboutUs.module.scss';

const AboutUs = () => {
    const { articles } = useContext(AppContext);

    return (
        <>
            <TopContentBlock
                titleBold="We build bridges"
                titleRest="between people and financial solutions"
                subtitle="Triton helps people get out of difficult situations due to the large number of loans and improper communication with banks and financial institutions."
                image="./images/svg/bridge.svg"
                imageMob="./images/svg/bridge-mob.svg"
                variant="secondary"
            />
            <WhoWeAre />
            <div className={s.doubleSections}>
                {ABOUT_DOUBLE_SECTIONS_ITEMS.map((props, i) => (
                    <DoubleSection key={i} {...props} variant="secondary" />
                ))}
            </div>
            <Partners />
            <History />
            <ArticleSection
                titleBold="Check interesting articles"
                titleRest="about financial solutions on our blog"
                subtitle="We also have useful materials on financial literacy and communication with creditors"
                numberOfArticles={3}
                linkTo={ROUTES.blog}
                linkName="Visit our blog"
                articles={articles}
            />

            <CalculatorSection />
        </>
    );
};

export default AboutUs;
