export const CREDIT_SCORE_SOLUTIONS_DOUBLE_SECTIONS_ITEMS = [
    {
        image: './images/credit-score-solutions/comprehensive.webp',
        imageMob: './images/credit-score-solutions/comprehensive.webp',
        title: 'Comprehensive Strategies',
        titleBold: 'Comprehensive',
        titleRest: 'Strategies',
        text: 'Our Comprehensive Strategies are second to none and its been proven time and time again. Instead of just a Generic Dispute process that the majority of companies are using; we pride ourselves on our multi-tiered comprehensive process that ensures more deletions and even happier clients and partners!',
        follow: 'button',
        imagePosition: 'left',
        noHelperText: true,
    },
    {
        image: './images/credit-score-solutions/innovative.webp',
        imageMob: './images/credit-score-solutions/innovative.webp',
        title: 'Innovative & Adaptive Methods',
        titleBold: 'Innovative & Adaptive',
        titleRest: 'Methods',
        text: 'Triton strives to stay ahead of the game and make sure that any changes in industry standards, regulations, or restrictions are implemented and executed successfully.',
        follow: 'button',
        imagePosition: 'right',
        noHelperText: true,
    },
    {
        image: './images/credit-score-solutions/results.webp',
        imageMob: './images/credit-score-solutions/results.webp',
        title: 'We Get Results!',
        titleBold: 'We Get',
        titleRest: 'Results!',
        text: 'Not only do we pride ourselves in being one of the fastest and most affordable programs in the industry, we produce real, effective, and lasting results. We average a modest 15-30 point increase in our clients scores every month! (Individual results will vary).',
        follow: 'button',
        imagePosition: 'left',
        noHelperText: true,
    },
];
