import { useCallback } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { actionsSelector, useQuizStore } from '../../store';
import { fadeInUp } from '../../../../helpers/utils/animations';
import { ROUTES } from '../../../../constants/constants';
import { STEPS_TITLE_ITEMS, QUIZ_DISCLAIMER_DEFAULT } from '../../constants';
import Container from '../../../Container';
import IconDone from '../../../ui/Icons/IconDone';
import Button from '../../../ui/Button';
import s from './QuizTitle.module.scss';
import { useShallow } from 'zustand/react/shallow';

/**
 *
 * @param {{
 *  className?: string;
 *  showCTA?: boolean;
 *  disclaimerText?: string
 * }} props
 */
const QuizTitle = ({ className, showCTA = true, disclaimerText = QUIZ_DISCLAIMER_DEFAULT }) => {
    const quizActions = useQuizStore(useShallow(actionsSelector));

    const navigate = useNavigate();

    const startQuestionnaireClickHandler = useCallback(() => {
        quizActions.cleanUp();

        navigate(`${ROUTES.quiz}/${ROUTES.questionnaire}/${ROUTES.understandingNeeds}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate]);

    return (
        <Container className={clsx(s.wrap, className)}>
            <div className={s.title}>
                <motion.h1 custom={1} variants={fadeInUp}>
                    <strong>Get help</strong> with your debt and credit today
                </motion.h1>
            </div>

            <div className={s.subtitle}>
                <motion.h4 custom={2} variants={fadeInUp}>
                    Find out how three simple steps can help you finally pay your debt off
                </motion.h4>
            </div>

            <motion.div
                className={clsx(s.holder, 'steps-wrap')}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
            >
                {STEPS_TITLE_ITEMS.map((item, i) => (
                    <motion.div custom={i + 1} variants={fadeInUp} className={s.card} key={i}>
                        <div className={s.cardHeader}>
                            <IconDone className={s.icon} />
                            <div className={s.cardTitle}>
                                <h3>
                                    {item.title} <span>#{i + 1}</span>
                                </h3>
                            </div>
                        </div>
                        <div className={s.cardDesc}>
                            <h5>{item.text}</h5>
                        </div>
                    </motion.div>
                ))}
            </motion.div>
            {showCTA ? (
                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }}>
                    <motion.div variants={fadeInUp} className={s.action}>
                        <Button onClick={startQuestionnaireClickHandler} responsive>
                            Start the questionnaire
                        </Button>
                        <p className={s.helperText}>This will not affect your credit score</p>
                    </motion.div>
                </motion.div>
            ) : null}

            <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }}>
                <motion.div variants={fadeInUp} className={s.basement}>
                    {disclaimerText}
                </motion.div>
            </motion.div>
        </Container>
    );
};

export default QuizTitle;
