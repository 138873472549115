import { useMemo } from 'react';
import { MEDICAL_DEBT_QUIZ_STEPS } from '../constants';

export const useMedicalDebtQuizStepperActiveStepIndex = () => {
    const activeStepIndex = useMemo(() => {
        const stepNumberFromStepperConfig = Object.values(MEDICAL_DEBT_QUIZ_STEPS).find((step) => {
            return window.location.pathname.includes(step.step_name);
        });

        return stepNumberFromStepperConfig?.step - 1 ?? 0;
    }, []);

    return activeStepIndex;
};
