export const CARDS = [
    {
        image: './images/fill.svg',
        title: 'Complete a short questionnaire',
        desc: 'Our brief questionnaire analyzes your needs and instantly matches you with the best available options, without affecting your score',
    },
    {
        image: './images/select.svg',
        title: 'See if you qualify',
        desc: 'We analyze your questionnaire to determine if you may be eligible for financial aid or a reduction in your medical bills.',
    },
    {
        image: './images/solution.svg',
        title: 'Get started',
        desc: 'We help eligible consumers submit financial aid and negotiate a reduced medical bill.',
    },
];
