import { useCallback } from 'react';
import { State } from 'country-state-city';
import { useShallow } from 'zustand/react/shallow';
import { allFieldsSelector, understandingNeedsStepSelector, useCreditRestorationQuizStore } from '../../../store';
import { useCreateHubspotSubmission } from '../../../../../hooks/api/useHubspotApi';
import { HUBSPOT_FORMS_GUID, HUBSPOT_FORMS_PORTAL_ID, US_CODE } from '../../../../../constants/constants';
import { useQuizStore } from '../../../../Quiz/store';
import { QUIZ_GOAL_TYPES } from '../../../../Quiz/constants';

export const useCreateCreditRestorationHubspotSubmission = () => {
    const { goalType } = useQuizStore(useShallow(understandingNeedsStepSelector));

    const { creditScoreInterest, creditGoals, timeline, firstName, lastName, email, phone, state, city, zip, address } =
        useCreditRestorationQuizStore(allFieldsSelector);

    const { mutate: createHubspotSubmission, isLoading, ...rest } = useCreateHubspotSubmission();

    const handleCreateHubspotSubmission = useCallback(
        (onSuccess) => {
            const { name: stateNameByCode } = State.getStateByCodeAndCountry(state, US_CODE);

            const product = goalType === QUIZ_GOAL_TYPES.improveCredit.value ? 'Credit' : 'Debt';

            const productField = {
                name: 'Product Type',
                value: product,
            };
            const firstNameField = {
                name: 'firstname',
                value: firstName,
            };
            const lastNameField = {
                name: 'lastname',
                value: lastName,
            };
            const emailField = {
                name: 'email',
                value: email,
            };
            const phoneField = {
                name: 'phone',
                value: phone,
            };
            const stateField = {
                name: 'state',
                value: stateNameByCode,
            };
            const cityField = {
                name: 'city',
                value: city,
            };
            const addressField = {
                name: 'address',
                value: address,
            };
            const zipField = {
                name: 'zip',
                value: zip,
            };

            const creditScoreInterestField = {
                name: 'Are you interested in restoring your credit and increasing your credit score?',
                value: creditScoreInterest,
            };

            const creditGoalsField = {
                name: 'What are your credit goals?',
                value: creditGoals,
            };
            const motivationField = {
                name: 'How motivated are you to transform your credit?',
                value: timeline,
            };

            const requestDataFields = [
                firstNameField,
                lastNameField,
                emailField,
                phoneField,
                creditScoreInterestField,
                creditGoalsField,
                motivationField,
                stateField,
                cityField,
                addressField,
                zipField,
                productField,
            ];

            createHubspotSubmission(
                {
                    fields: requestDataFields,
                    formGuid: HUBSPOT_FORMS_GUID,
                    portalId: HUBSPOT_FORMS_PORTAL_ID,
                },
                {
                    onSuccess,
                }
            );
        },
        [
            address,
            city,
            creditGoals,
            creditScoreInterest,
            email,
            firstName,
            goalType,
            lastName,
            phone,
            state,
            timeline,
            zip,
            createHubspotSubmission,
        ]
    );

    return { handleCreateHubspotSubmission, isLoading, ...rest };
};
