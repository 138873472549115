import { memo, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { motion } from 'framer-motion';
import { actionsSelector, deniedMedicAidSelector, mortgageSelector, useMedicalDebtQuizStore } from '../../store';
import { fadeInLeft } from '../../../../helpers/utils/animations';
import { BOOLEAN_OPTIONS, ROUTES } from '../../../../constants/constants';
import Container from '../../../Container';
import Typography from '../../../Typography';
import RadioButton from '../../../ui/RadioButton';
import Button from '../../../ui/Button';
import IconArrowRight from '../../../ui/Icons/IconArrowRight';
import s from './MedicalDebtQuizMedicaidDeniedStep.module.css';

const MedicalDebtQuizMedicaidDeniedStep = () => {
    const navigate = useNavigate();

    const mortgage = useMedicalDebtQuizStore(useShallow(mortgageSelector));
    const deniedMedicAid = useMedicalDebtQuizStore(useShallow(deniedMedicAidSelector));
    const { setField } = useMedicalDebtQuizStore(useShallow(actionsSelector));

    const prevClickHandler = useCallback(() => {
        navigate(`../${ROUTES.mortgage}`);
    }, [navigate]);

    const nextClickHandler = useCallback(() => {
        navigate(`../${ROUTES.citizenship}`);
    }, [navigate]);

    const optionChangeHandler = useCallback(
        (newValue) => {
            return () => {
                setField('deniedMedicAid', newValue);
            };
        },
        [setField]
    );

    useEffect(() => {
        if (!mortgage) {
            navigate(`../${ROUTES.mortgage}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mortgage]);

    return (
        <Container>
            <motion.section className={s.wrap} custom={1} variants={fadeInLeft}>
                <Typography className={s.title} variant="heading-h2" asChild>
                    <h3>
                        Have you applied for <strong>Medicaid</strong> and been denied?
                    </h3>
                </Typography>
                <motion.div className={s['options-wrap']} variants={fadeInLeft}>
                    {BOOLEAN_OPTIONS.map((option) => {
                        return (
                            <RadioButton
                                key={option.value}
                                className={s.option}
                                checked={option.value === deniedMedicAid}
                                label={option.label}
                                value={option.value}
                                onChange={optionChangeHandler(option.value)}
                            />
                        );
                    })}
                </motion.div>
                <footer className={s.footer}>
                    <Button className={s.cta} onClick={prevClickHandler} variant="sixth" responsive>
                        Back
                    </Button>
                    <Button
                        className={s.cta}
                        onClick={nextClickHandler}
                        Icon={IconArrowRight}
                        disabled={!deniedMedicAid}
                        responsive
                    >
                        Next
                    </Button>
                </footer>
            </motion.section>
        </Container>
    );
};
export default memo(MedicalDebtQuizMedicaidDeniedStep);
