import { memo, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { motion } from 'framer-motion';
import {
    actionsSelector,
    householdIncomeSelector,
    householdMembersSelector,
    useMedicalDebtQuizStore,
} from '../../store';
import { fadeInLeft } from '../../../../helpers/utils/animations';
import { ROUTES } from '../../../../constants/constants';
import { HOUSEHOLD_INCOME_OPTIONS } from '../../constants';
import Container from '../../../Container';
import Typography from '../../../Typography';
import RadioButton from '../../../ui/RadioButton';
import Button from '../../../ui/Button';
import IconArrowRight from '../../../ui/Icons/IconArrowRight';
import s from './MedicalDebtQuizHouseholdIncomeStep.module.css';

const MedicalDebtQuizHouseholdIncomeStep = () => {
    const navigate = useNavigate();

    const householdMembers = useMedicalDebtQuizStore(useShallow(householdMembersSelector));
    const householdIncome = useMedicalDebtQuizStore(useShallow(householdIncomeSelector));
    const { setField } = useMedicalDebtQuizStore(useShallow(actionsSelector));

    console.log(householdIncome);

    const prevClickHandler = useCallback(() => {
        navigate(`../${ROUTES.householdMembers}`);
    }, [navigate]);

    const nextClickHandler = useCallback(() => {
        navigate(`../${ROUTES.mortgage}`);
    }, [navigate]);

    const optionChangeHandler = useCallback(
        (newValue) => {
            return () => {
                setField('householdIncome', newValue);
            };
        },
        [setField]
    );

    useEffect(() => {
        if (!householdMembers) {
            navigate(`../${ROUTES.householdMembers}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [householdMembers]);

    return (
        <Container>
            <motion.section className={s.wrap} custom={1} variants={fadeInLeft}>
                <Typography className={s.title} variant="heading-h2" asChild>
                    <h3>
                        What is your estimated <strong>household</strong> income?
                    </h3>
                </Typography>
                <motion.div className={s['options-wrap']} variants={fadeInLeft}>
                    {HOUSEHOLD_INCOME_OPTIONS.map((option) => {
                        return (
                            <RadioButton
                                key={option}
                                className={s.option}
                                checked={option === householdIncome}
                                label={option}
                                value={option}
                                onChange={optionChangeHandler(option)}
                            />
                        );
                    })}
                </motion.div>
                <footer className={s.footer}>
                    <Button className={s.cta} onClick={prevClickHandler} variant="sixth" responsive>
                        Back
                    </Button>
                    <Button
                        className={s.cta}
                        onClick={nextClickHandler}
                        Icon={IconArrowRight}
                        disabled={!householdIncome}
                        responsive
                    >
                        Next
                    </Button>
                </footer>
            </motion.section>
        </Container>
    );
};
export default memo(MedicalDebtQuizHouseholdIncomeStep);
