import { memo, useCallback, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { State, City } from 'country-state-city';
import { PatternFormat } from 'react-number-format';
import { actionsSelector, configSelector, useCreditRestorationQuizStore } from '../../../../store';
import { useQuizPersonalInfoAddressForm } from './hooks/useQuizPersonalInfoAddressForm';
import { US_CODE } from '../../../../../../constants/constants';
import Select from '../../../../../ui/Select/Select';
import Input from '../../../../../ui/Input/Input';
import s from './QuizPersonalInfoAddressForm.module.scss';

const QuizPersonalInfoAddressForm = () => {
    const { currentStepNumber } = useCreditRestorationQuizStore(useShallow(configSelector));
    const quizActions = useCreditRestorationQuizStore(useShallow(actionsSelector));

    const {
        stateController: {
            field: stateField,
            fieldState: { error: stateError },
        },
        cityController: {
            field: cityField,
            fieldState: { error: cityError },
        },
        addressController: {
            field: addressField,
            fieldState: { error: addressError },
        },
        zipController: {
            field: zipField,
            fieldState: { error: zipError },
        },
    } = useQuizPersonalInfoAddressForm();

    const memoizedStatesOptions = useMemo(() => {
        const allStates = State.getStatesOfCountry(US_CODE);

        return allStates.map((state) => {
            return {
                label: state.name,
                value: state.isoCode,
            };
        });
    }, []);

    const memoizedCitiesOptions = useMemo(() => {
        const allCitiesByState = City.getCitiesOfState(US_CODE, stateField.value);

        return allCitiesByState.map((city) => {
            return {
                label: city.name,
                value: city.name,
            };
        });
    }, [stateField.value]);

    const memoizedStateSelectValue = useMemo(() => {
        return memoizedStatesOptions.find((state) => {
            return state.value === stateField.value;
        });
    }, [memoizedStatesOptions, stateField.value]);

    const memoizedCitySelectValue = useMemo(() => {
        if (!cityField.value) {
            return null;
        }

        return memoizedCitiesOptions?.find((city) => {
            return city.value === cityField.value;
        });
    }, [memoizedCitiesOptions, cityField.value]);

    const stateFieldChangeHandler = useCallback(
        (stateCode) => {
            stateField.onChange(stateCode);
            cityField.onChange(null);

            quizActions.setFields({
                state: stateCode,
                city: null,
            });
        },
        [cityField, quizActions, stateField]
    );

    const cityFieldChangeHandler = useCallback(
        (cityName) => {
            cityField.onChange(cityName);
            quizActions.setField('city', cityName);
        },
        [cityField, quizActions]
    );

    const addressFieldChangeHandler = useCallback(
        (event) => {
            addressField.onChange(event.target.value);
            quizActions.setField('address', event.target.value);
        },
        [addressField, quizActions]
    );

    const zipFieldChangeHandler = useCallback(
        (numberFormatValue) => {
            zipField.onChange(numberFormatValue.value);
            quizActions.setField('zip', numberFormatValue.value);
        },
        [quizActions, zipField]
    );

    return (
        <div className={s.wrap}>
            <Select
                {...stateField}
                label="State"
                placeholder="Choose your state"
                instanceId={stateField.name}
                options={memoizedStatesOptions}
                value={memoizedStateSelectValue}
                isSearchable
                error={!!stateError?.message}
                helperText={stateError?.message}
                isDisabled={currentStepNumber === 4}
                autoComplete="off"
                onChange={stateFieldChangeHandler}
            />
            <Select
                {...cityField}
                label="City"
                placeholder="Choose your city"
                instanceId={cityField.name}
                options={memoizedCitiesOptions}
                value={memoizedCitySelectValue}
                isSearchable
                error={!!cityError?.message}
                helperText={cityError?.message}
                isDisabled={currentStepNumber === 4 || !stateField.value}
                autoComplete="off"
                onChange={cityFieldChangeHandler}
            />
            <Input
                {...addressField}
                label="Street address"
                placeholder="Type your street address"
                error={!!addressError?.message}
                helperText={addressError?.message}
                disabled={currentStepNumber === 4}
                autoComplete="off"
                onChange={addressFieldChangeHandler}
            />
            <PatternFormat
                format="#####"
                mask="_"
                label="Zip"
                placeholder="Type your zip code"
                error={!!zipError?.message}
                helperText={zipError?.message}
                value={zipField.value}
                customInput={Input}
                disabled={currentStepNumber === 4}
                autoComplete="off"
                onValueChange={zipFieldChangeHandler}
            />
        </div>
    );
};

export default memo(QuizPersonalInfoAddressForm);
