import { Suspense, createContext, lazy, useContext, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Route, Routes, useLocation } from 'react-router-dom';
import { TESTIMONIAL_TITLES } from './config/constants';
import { ROUTES } from './constants/constants';
import { useSmoothScroll } from './helpers/hooks/useSmoothScroll';
import { useMarketingIdStorage } from './hooks/useMarketingIdStorage';
import MainLayout from './layouts/MainLayout';
import AboutUs from './pages/AboutUs/AboutUs';
import AgreementsPage from './pages/AgreementsPage';
import NotFound from './components/404';
import QuizTitle from './components/Quiz/components/QuizTitle';
import Loader from './components/ui/loader';
import Api from './pages/Api/Api';
import ArticlePage from './pages/ArticlePage/ArticlePage';
import Blog from './pages/Blog/Blog';
import GetOptions from './pages/GetOptions/GetOptions';
import HomePage from './pages/HomePage/HomePage';
import WeHelp from './pages/WeHelp/WeHelp';
import CreditScoreSolutions from './pages/CreditScoreSolution/CreditScoreSolutions';
import CreditRestorationQuizPage from './pages/CreditRestorationQuizPage';
import MedicalDebtPage from './pages/MedicalDebtPage';
import MedicalDebtQuizPage from './pages/MedicalDebtQuizPage';
import DebtSolutionsPage from './pages/DebtSolutionsPage/DebtSolutionsPage';

const QuizPage = lazy(() => {
    return import('./pages/QuizPage');
});
const QuizAlphaPage = lazy(() => {
    return import('./pages/QuizAlphaPage');
});

export const HeaderContext = createContext();
export const AppContext = createContext();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry(failureCount, error) {
                const isAxiosError = error instanceof AxiosError;
                const isTrying = failureCount < 2;

                if (!isAxiosError) {
                    return isTrying;
                }

                return isTrying;
            },
        },
    },
});

function App() {
    const location = useLocation();

    const [mobMenuIsOpen, setMobMenuIsOpen] = useState(false);
    const [loaderBlock, setLoaderBlock] = useState(false);
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [messageModalText, setMessageModalText] = useState('');
    const [articles, setArticles] = useState([]);
    const [testimonials, setTestimonials] = useState([]);

    useSmoothScroll();
    useMarketingIdStorage();

    const getArticles = async () => {
        setLoaderBlock(true);
        const a = await axios.get('https://us-central1-triton-oracle.cloudfunctions.net/getArticles');
        const b = Object.keys(a.data);
        let listToSet = [];
        let testimonialsToSet = [];
        for (let i = 0; i < b.length; i++) {
            const thisArticle = a.data[b[i]];
            if (TESTIMONIAL_TITLES[thisArticle.title]) {
                testimonialsToSet.push(thisArticle);
            } else {
                listToSet.push(thisArticle);
            }
        }
        setArticles(listToSet);
        setTestimonials(testimonialsToSet);
        setLoaderBlock(false);
    };

    useEffect(() => {
        getArticles();
    }, []);

    //   useEffect(() => {
    //     const marketingId = searchParams.get("subid");

    //     if (marketingId) {
    //       localStorage.setItem("marketingId", marketingId);
    //       Cookies.set(COOKIE_MARKETING_ID, marketingId, { expires: 30 });
    //     }
    //   }, [searchParams]);

    return (
        <QueryClientProvider client={queryClient}>
            <HeaderContext.Provider
                value={{
                    mobMenuIsOpen,
                    setMobMenuIsOpen,
                    loaderBlock,
                    setLoaderBlock,
                    messageModalOpen,
                    messageModalText,
                    setMessageModalOpen,
                    setMessageModalText,
                }}
            >
                <AppContext.Provider value={{ articles, testimonials }}>
                    <Routes location={location} key={location.pathname}>
                        <Route path="/" element={<MainLayout />}>
                            <Route index element={<HomePage />} />
                            <Route path={`${ROUTES.medicalDebt}/*`}>
                                <Route index element={<MedicalDebtPage />} />
                                <Route path={`${ROUTES.questionnaire}/*`} element={<MedicalDebtQuizPage />} />
                            </Route>
                            <Route path={ROUTES.about} element={<AboutUs />} />
                            <Route path={ROUTES.help} element={<WeHelp />} />
                            <Route path={ROUTES.debtSolutions} element={<DebtSolutionsPage />} />
                            {/* NOTE: temp disabled to navigate to this page */}
                            <Route path={ROUTES.creditScoreSolutions} element={<CreditScoreSolutions />} />
                            <Route path={ROUTES.options} element={<GetOptions />} />
                            <Route
                                path={`${ROUTES.quiz}/*`}
                                element={
                                    <Suspense fallback={<Loader />}>
                                        <QuizPage />
                                    </Suspense>
                                }
                            >
                                <Route index element={<QuizTitle />} />
                            </Route>
                            <Route
                                path={ROUTES.newQuiz}
                                element={
                                    <Suspense fallback={<Loader />}>
                                        <QuizAlphaPage />
                                    </Suspense>
                                }
                            />
                            <Route path={`${ROUTES.creditRestorationQuiz}/*`} element={<CreditRestorationQuizPage />} />
                            <Route path={ROUTES.blog}>
                                <Route index element={<Blog />} />
                                <Route path={ROUTES.blogId} element={<ArticlePage />} />
                            </Route>
                            <Route path="/policy" element={<AgreementsPage />} />
                            <Route path="/terms" element={<AgreementsPage />} />
                            <Route path="/api" element={<Api />} />

                            <Route path="*" element={<NotFound />} />
                        </Route>
                    </Routes>
                </AppContext.Provider>
            </HeaderContext.Provider>
        </QueryClientProvider>
    );
}

export default App;
