export const ABOUT_DOUBLE_SECTIONS_ITEMS = [
    {
        image: './images/svg/graffic.svg',
        imageMob: './images/svg/graffic-mob.svg',
        titleBold: 'mission',
        titleRest: 'Our',
        text: 'To support greater equity and promote social mobility with financial literacy in mind. Through honest service, we achieve a business that makes this possible',
        imagePosition: 'right',
    },
    {
        image: './images/svg/persona.svg',
        imageMob: './images/svg/persona-mob.svg',
        titleBold: 'we help?',
        titleRest: 'Who',
        text: 'Triton aims to help consumers facing some form of financial hardship. Hardships can range from being severely in debt to having difficulty saving as a result of limited resources',
        follow: 'link',
    },
];
