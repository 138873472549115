import { motion } from 'framer-motion';
import clsx from 'clsx';
import { fadeInUp } from '../../../../helpers/utils/animations';
import { ROUTES } from '../../../../constants/constants';
import { CARDS } from './constants';
import LinkButton from '../../../../components/LinkButton';
import Container from '../../../../components/Container';
import TripleSectionCard from './TripleSectionCard';
import styles from './TripleSection.module.scss';

const TripleSection = () => {
    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={clsx(styles.section, 'o-hidden')}
        >
            <Container>
                <motion.h2 custom={1} variants={fadeInUp} className={styles.title}>
                    How does our <strong>service work?</strong>
                </motion.h2>
                <motion.p custom={2} variants={fadeInUp} className={styles.subtitle}>
                    See how three simple steps can help you finally lower your interest and pay off your debt
                </motion.p>

                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} className={styles.holder}>
                    {CARDS.map((props, i) => (
                        <motion.div custom={i + 1} variants={fadeInUp} key={i}>
                            <TripleSectionCard {...props} index={i + 1} />
                        </motion.div>
                    ))}
                </motion.div>
                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }}>
                    <motion.div variants={fadeInUp}>
                        <LinkButton hasIcon noHelperText linkTo={`${ROUTES.medicalDebt}/${ROUTES.questionnaire}`} />
                    </motion.div>
                </motion.div>
            </Container>
        </motion.section>
    );
};

export default TripleSection;
