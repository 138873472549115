import { useController, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useShallow } from 'zustand/react/shallow';
import { medicalDebtQuizAddressSchema } from '../schemas';
import { addressSelector, useMedicalDebtQuizStore } from '../../../store';

export const useMedicalDebtQuizAddressForm = () => {
    const { state, city, address, zip } = useMedicalDebtQuizStore(useShallow(addressSelector));

    const { control, ...rest } = useForm({
        resolver: zodResolver(medicalDebtQuizAddressSchema),
        defaultValues: {
            state,
            city,
            address,
            zip,
        },
        mode: 'all',
    });

    const stateController = useController({
        control,
        name: 'state',
    });

    const cityController = useController({
        control,
        name: 'city',
    });

    const addressController = useController({
        control,
        name: 'address',
    });

    const zipController = useController({
        control,
        name: 'zip',
    });

    return {
        stateController,
        cityController,
        addressController,
        zipController,
        ...rest,
    };
};
