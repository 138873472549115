import React from 'react';
import ButtonsGroup from '../../components/ButtonsGroup/ButtonsGroup';
import s from './AgreementsPage.module.scss';
import clsx from 'clsx';

const Policy = () => {
    return (
        <div className={s.content}>
            <h1 className="h1">Privacy Policy</h1>
            <h4 className="h4">Effective Date: January 1, 2025</h4>
            <h3 className="h3">1. Introduction</h3>
            <p>
                Triton Finance ("we," "us," or "our") is committed to protecting the privacy and security of personal
                information collected from visitors and users ("you" or "your") of our website. This Privacy Policy
                outlines:
            </p>
            <ul>
                <li>The types of information we collect,</li>
                <li>How we use, share, and protect that information, and</li>
                <li>Your rights regarding your personal information.</li>
            </ul>
            <p>By using our website, you acknowledge and agree to the practices outlined in this Privacy Policy.</p>

            <h3 className="h3">2. Information We Collect</h3>
            <p>We may collect and process the following types of personal information:</p>
            <h5>a. Personal Information (PI)</h5>
            <p>We collect personal information you voluntarily provide, such as:</p>
            <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Contact preferences</li>
            </ul>
            <h5>b. Financial Information</h5>
            <p>If you choose to engage with our services, we may collect financial data such as:</p>
            <ul>
                <li>Estimated debt amount</li>
                <li>Credit score range</li>
                <li>
                    Other financial details necessary for evaluating eligibility for our third-party providers&apos;
                    services
                </li>
            </ul>
            <p className={clsx(s.bold, s.black)}>
                🚫 We do not collect sensitive financial data such as credit card numbers, bank account details, or
                Social Security Numbers (SSNs).
            </p>
            <h5>c. Automatically Collected Information (Usage Data)</h5>
            <p>When you visit our website, we may automatically collect:</p>
            <ul>
                <li>
                    <strong>Device Information</strong>: Browser type, operating system, device model
                </li>
                <li>
                    <strong>Log Data</strong>: IP address, referral URLs, pages visited, session duration
                </li>
                <li>
                    <strong>Cookies & Tracking Technologies</strong>: We use cookies and tracking tools to improve
                    website functionality. See{' '}
                    <a href="#cookies">
                        <strong>Section 8: Cookies & Tracking</strong>
                    </a>{' '}
                    for details.
                </li>
            </ul>

            <h3 className="h3">3. How We Use Your Information</h3>
            <p>We use your personal information to:</p>
            <ul className={s.list} data-list-type="checkboxes">
                <li>Personalize your website experience (e.g., customized recommendations)</li>
                <li>Respond to inquiries and provide customer support</li>
                <li>
                    Connect you with third-party service providers for <strong>debt consolidation</strong> or{' '}
                    <strong>credit repair</strong>
                </li>
                <li>Comply with legal and regulatory requirements</li>
                <li>Prevent fraud, security threats, or unauthorized access</li>
            </ul>
            <h5>🔹 Marketing &amp; Communication:</h5>
            <ul>
                <li>You may receive emails about services, promotions, or updates.</li>
                <li>
                    You can opt-out anytime (see{' '}
                    <a href="#privacy-rights">
                        <strong>Section 7</strong>
                    </a>
                    ).
                </li>
            </ul>

            <h3>4. Information Sharing &amp; Third Parties</h3>
            <p>
                We do not <strong>sell</strong> or <strong>rent</strong> your personal information. However, we may
                share it in the following situations:
            </p>
            <h5>a. Service Providers &amp; Partners</h5>
            <p>
                We work with trusted third-party providers to offer{' '}
                <strong>debt consolidation and credit repair services</strong>. Your information may be shared with them
                <strong>only if you qualify</strong> and consent to a consultation.
            </p>
            <h5>b. Legal &amp; Regulatory Compliance</h5>
            <p>We may disclose your information:</p>
            <ul>
                <li>To comply with applicable laws, regulations, or legal processes</li>
                <li>To protect our rights, privacy, safety, or property</li>
                <li>In response to valid law enforcement requests or court orders</li>
            </ul>
            <h5>c. Business Transfers</h5>
            <p>
                If Triton Finance undergoes a merger, acquisition, or sale, your data may be transferred as part of the
                business assets. You will be notified of any such changes.
            </p>

            <h3 className="h3">5. Data Security</h3>
            <p>We implement industry-standard security measures to protect your data, including:</p>
            <ul className={s.list} data-list-type="locks">
                <li>
                    <strong>Encryption</strong> (for sensitive data transmission)
                </li>
                <li>
                    <strong>Access Controls</strong> (limiting access to authorized personnel)
                </li>
                <li>
                    <strong>Firewalls &amp; Secure Servers</strong>
                </li>
            </ul>
            <p>
                However, <strong>no method of transmission is 100% secure</strong>. We cannot guarantee absolute
                security, and you share your information at your own risk.
            </p>

            <h3>6. Data Retention</h3>
            <p>
                We retain personal information <strong>only as long as necessary</strong> for the purposes stated in
                this policy or to comply with legal obligations. <strong>Retention periods include</strong>:
            </p>
            <ul className={s.list} data-list-type="pins">
                <li>
                    General inquiries: <strong>Up to 12 months</strong>
                </li>
                <li>
                    Service-related data: <strong>Up to 5 years</strong> (to comply with financial regulations)
                </li>
                <li>
                    Marketing data: <strong>Until you opt-out</strong>
                </li>
            </ul>
            <p>After retention periods expire, data is securely deleted or anonymized.</p>

            <h3 id="privacy-rights" className="h3">
                7. Your Privacy Rights
            </h3>
            <h5>a. Your Rights Under U.S. Privacy Laws</h5>
            <p>Depending on your state, you may have the following rights:</p>
            <ul className={s.list} data-list-type="diamonds">
                <li>
                    <strong>Right to Access</strong> - Request a copy of your personal information
                </li>
                <li>
                    <strong>Right to Correct</strong> - Request corrections to inaccurate data
                </li>
                <li>
                    <strong>Right to Delete</strong> - Request deletion of your data (subject to legal exceptions)
                </li>
                <li>
                    <strong>Right to Opt-Out</strong> - Decline marketing communications
                </li>
                <li>
                    <strong>Right to Non-Discrimination</strong> - We will not penalize you for exercising your privacy
                    rights
                </li>
            </ul>
            <p>
                To exercise any of these rights, email us at&nbsp;
                <a href="mailto:privacy@triton.finance">
                    <strong>privacy@triton.finance</strong>
                </a>
                .
            </p>
            <h5>b. Opting Out of Marketing</h5>
            <ul>
                <li>Click &quot;Unsubscribe&quot; in marketing emails</li>
                <li>
                    Email{' '}
                    <a href="mailto:privacy@triton.finance">
                        <strong>privacy@triton.finance</strong>
                    </a>{' '}
                    with "OPT-OUT" in the subject line
                </li>
            </ul>

            <h3 id="cookies" className="h3">
                8. Cookies & Tracking Technologies
            </h3>
            <p>We use cookies and tracking tools to enhance your browsing experience, such as:</p>
            <ul className={s.list} data-list-type="cookies">
                <li>
                    <strong>Essential Cookies</strong> - Necessary for website functionality
                </li>
                <li>
                    <strong>Analytics Cookies</strong> - To improve user experience (Google Analytics, etc.)
                </li>
                <li>
                    <strong>Advertising Cookies</strong> - Used for targeted ads
                </li>
            </ul>
            <p>
                🚨 <strong>You can manage or disable cookies</strong> via your browser settings.
            </p>

            <h3>9. Children's Privacy</h3>
            <p>
                🚫 Our services are <strong>not intended for children under 18</strong>. We do not knowingly collect
                data from minors. If you believe a minor has provided us with personal data, contact us immediately.
            </p>

            <h3>10. Third-Party Websites</h3>
            <p>
                Our website may contain links to third-party websites. We are <strong>not responsible</strong> for their
                privacy practices. We encourage you to review their policies before submitting any data.
            </p>

            <h3>11. Changes to This Privacy Policy</h3>
            <p>
                We <strong>may update</strong> this Privacy Policy periodically. When changes occur, we will:
            </p>
            <ul className={s.list} data-list-type="checkboxes">
                <li>
                    Update the <strong>&quot;Effective Date&quot;</strong> at the top of this page
                </li>
                <li>Notify users via website notice or email (if significant changes are made)</li>
            </ul>
            <p>We encourage you to review this policy regularly.</p>

            <h3 className="h3">12. Contact Us</h3>
            <p>If you have any questions or requests regarding this Privacy Policy, please contact us:</p>
            <br />
            <p>
                📍 <strong>Triton Finance</strong>
                <br />
                <address>3500 S Dupont Hwy, Dover, DE 19901</address>
                📧{' '}
                <a href="mailto:legal@triton.finance">
                    <strong>legal@triton.finance</strong>
                </a>
            </p>
            <ButtonsGroup />
        </div>
    );
};

export default Policy;
