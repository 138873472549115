import clsx from 'clsx';
import styles from './WhoWeAre.module.scss';
import Container from '../Container/Container';
import { VALUES } from './constants';
import { fadeInLeft, fadeInRight, fadeInUp } from '../../helpers/utils/animations';
import { motion } from 'framer-motion';

const WhoWeAre = () => {
    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={clsx(styles.section, 'o-hidden')}
        >
            <Container>
                <div className={styles.holder}>
                    <motion.div custom={2} variants={fadeInLeft}>
                        <picture className={styles.image}>
                            <source srcSet="./images/svg/laptop-mob.svg" media="(max-width: 479px)" />
                            <img src="./images/svg/laptop.svg" alt="Who we are?" />
                        </picture>
                    </motion.div>
                    <div className={styles.contentWrap}>
                        <div className={styles.content}>
                            <div className={styles.textBlock}>
                                <motion.h2 custom={1} variants={fadeInRight}>
                                    Who <strong>we are?</strong>
                                </motion.h2>
                                <motion.p custom={2} variants={fadeInRight} className={styles.subtitle}>
                                    Triton Finance is a financial consulting firm that focuses on providing essential
                                    guidance to an underserved market. By utilizing expert knowledge and innovative
                                    approaches, we connect consumers with the tools they need to overcome financial
                                    challenges and prosper over the longterm
                                </motion.p>
                            </div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                className={styles.values}
                            >
                                {VALUES.map((item, i) => (
                                    <motion.div custom={i + 1} variants={fadeInUp} className={styles.number} key={i}>
                                        <p className={styles.quantity}>
                                            {item.number}
                                            <span>+</span>
                                        </p>
                                        <p className={styles.helperText}>{item.text}</p>
                                    </motion.div>
                                ))}
                            </motion.div>
                        </div>
                    </div>
                </div>
            </Container>
        </motion.section>
    );
};

export default WhoWeAre;
