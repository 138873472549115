import { ROUTES } from '../../constants/constants';

export const MEDICAL_DEBT_QUIZ_TITLES = [
    'Complete a simple questionnaire to help us better understand your needs',
    'Answer a few simple questions to verify who you are',
    'Get connected with expert help to assist with your medical debt',
];

const BOOLEAN_OPTIONS = ['Yes', 'No'];

export const HOUSEHOLD_MEMBERS_OPTIONS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

export const HOUSEHOLD_INCOME_OPTIONS = ['$0 - $35,999', '$36,000 - $65,999', '$66,000 - $85,999', '$86,000+'];

export const HOUSEHOLD_MEMBERS_SELECT_OPTIONS = HOUSEHOLD_MEMBERS_OPTIONS.map((option) => {
    return {
        label: option,
        value: option,
    };
});

export const MEDICAL_DEBT_QUIZ_STEPS_OPTIONS = {
    [ROUTES.medicaidService]: BOOLEAN_OPTIONS,
    [ROUTES.cityResidence]: BOOLEAN_OPTIONS,
    [ROUTES.citizenship]: BOOLEAN_OPTIONS,
    [ROUTES.householdIncome]: HOUSEHOLD_INCOME_OPTIONS,
    [ROUTES.householdMembers]: HOUSEHOLD_MEMBERS_OPTIONS,
    [ROUTES.mortgage]: BOOLEAN_OPTIONS,
    [ROUTES.countryOrCity]: BOOLEAN_OPTIONS,
    [ROUTES.deniedMedicAid]: BOOLEAN_OPTIONS,
};
