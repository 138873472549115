import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { middlewares } from '../../store/middlewares';
import { MEDICAL_DEBT_QUIZ_CITIZENSHIP_OPTIONS } from './constants';
import { ROUTES } from '../../constants/constants';

/**
 * @typedef {{
 *  medicaidService: keyof typeof MEDICAL_DEBT_QUIZ_CITIZENSHIP_OPTIONS[ROUTES.medicaidService];
 *  citizenship: keyof typeof MEDICAL_DEBT_QUIZ_CITIZENSHIP_OPTIONS[ROUTES.citizenship];
 *  householdIncome: keyof typeof MEDICAL_DEBT_QUIZ_CITIZENSHIP_OPTIONS[ROUTES.householdIncome];
 *  householdMembers: keyof typeof MEDICAL_DEBT_QUIZ_CITIZENSHIP_OPTIONS[ROUTES.householdMembers];
 *  mortgage: keyof typeof MEDICAL_DEBT_QUIZ_CITIZENSHIP_OPTIONS[ROUTES.mortgage];
 *  deniedMedicAid: keyof typeof MEDICAL_DEBT_QUIZ_CITIZENSHIP_OPTIONS[ROUTES.deniedMedicAid];
 *  citizenship: keyof typeof MEDICAL_DEBT_QUIZ_CITIZENSHIP_OPTIONS[ROUTES.citizenship];
 *  firstName: string;
 *  lastName: string;
 *  email: string;
 *  phone: string;
 *  state: string;
 *  city: string;
 *  address: string;
 *  zip: string;
 * }} MedicalDebtQuizState
 * @type {MedicalDebtQuizState}
 */
export const MEDICAL_DEBT_QUIZ_INITIAL_STATE = {
    medicaidService: '',
    cityResidence: '',
    householdMembers: '',
    householdIncome: '',
    mortgage: '',
    deniedMedicAid: '',
    citizenship: '',
    // Contact details 1
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    // Contact details 2
    state: '',
    city: '',
    address: '',
    zip: '',
};

const createStore = persist(
    (set) => {
        return {
            ...MEDICAL_DEBT_QUIZ_INITIAL_STATE,
            /**
             * @param {keyof typeof MEDICAL_DEBT_QUIZ_INITIAL_STATE} key
             * @param {(typeof MEDICAL_DEBT_QUIZ_INITIAL_STATE)[keyof typeof MEDICAL_DEBT_QUIZ_INITIAL_STATE]} value
             */
            setField(key, value) {
                set(
                    (state) => {
                        state[key] = value;
                        return state;
                    },
                    false,
                    {
                        type: 'medical-debt-quiz/setField',
                        payload: {
                            key,
                            value,
                        },
                    }
                );
            },
            /**
             * @param {Partial<typeof MEDICAL_DEBT_QUIZ_INITIAL_STATE>} values
             */
            setFields(values) {
                set(
                    (state) => {
                        state = Object.assign(state, values);
                        return state;
                    },
                    false,
                    {
                        type: 'medical-debt-quiz/setFields',
                        payload: values,
                    }
                );
            },
            cleanUp() {
                set(MEDICAL_DEBT_QUIZ_INITIAL_STATE, false, {
                    type: 'medical-debt-quiz/cleanUp',
                });
            },
        };
    },
    {
        name: 'medical-debt-quiz-store',
        storage: createJSONStorage(() => {
            return sessionStorage;
        }),
    }
);

export const useMedicalDebtQuizStore = create()(middlewares('medical-debt-quiz-store', immer(createStore)));

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {Pick<MedicalDebtQuizState, 'medicaidService'>}
 */
export const medicaidServiceSelector = (state) => {
    return state.medicaidService;
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {Pick<MedicalDebtQuizState, 'cityResidence'>}
 */
export const cityResidenceSelector = (state) => {
    return state.cityResidence;
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {Pick<MedicalDebtQuizState, 'householdMembers'>}
 */
export const householdMembersSelector = (state) => {
    return state.householdMembers;
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {Pick<MedicalDebtQuizState, 'householdIncome'>}
 */
export const householdIncomeSelector = (state) => {
    return state.householdIncome;
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {Pick<MedicalDebtQuizState, 'mortgage'>}
 */
export const mortgageSelector = (state) => {
    return state.mortgage;
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {Pick<MedicalDebtQuizState, 'deniedMedicAid'>}
 */
export const deniedMedicAidSelector = (state) => {
    return state.deniedMedicAid;
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {Pick<MedicalDebtQuizState, 'citizenship'>}
 */
export const citizenshipSelector = (state) => {
    return state.citizenship;
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {Pick<MedicalDebtQuizState, 'firstName' | 'lastName' | 'email' | 'phone'>}
 */
export const contactDetailsSelector = (state) => {
    return {
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        phone: state.phone,
    };
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {Pick<MedicalDebtQuizState, 'state' | 'city' | 'address' | 'zip'>}
 */
export const addressSelector = (state) => {
    return {
        state: state.state,
        city: state.city,
        address: state.address,
        zip: state.zip,
    };
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {MedicalDebtQuizState}
 */
export const allFieldsSelector = (state) => {
    const { setField: _, setFields: __, cleanUp: ___, ...rest } = state;

    return rest;
};

/**
 * @param {ReturnType<typeof createStore>} state
 * @returns {Pick<ReturnType<typeof createStore>, 'cleanUp' | 'setField' | 'setFields'>}
 */
export const actionsSelector = (state) => {
    const { cleanUp, setField, setFields } = state;

    return {
        cleanUp,
        setField,
        setFields,
    };
};
