import clsx from 'clsx';
import { useContext } from 'react';
import ArticleSection from '../../components/Article/ArticleSection';
import CalculatorSection from '../../components/CalculatorSection/CalculatorSection';
import TopContentBlock from '../../components/TopContentBlock/TopContentBlock';
import TheeColumnSection from '../../components/ThreeColumnsSection/TheeColumnSection';
import ImageBlock from '../../components/ImageBlock/ImageBlock';
import { ROUTES } from '../../constants/constants';
import { whyTriton } from '../../config/whyTriton';
import { tritonFocus } from '../../config/tritonFocus';
import { AppContext } from '../../App';
import SolveProblems from '../../components/SolveProblems/SolveProblems';
import s from './WeHelp.module.scss';

const WeHelp = () => {
    const { articles, testimonials } = useContext(AppContext);

    return (
        <>
            <TopContentBlock
                titleBold="The main rule of our work is"
                titleRest="to solve our clients' problems"
                subtitle="On this page you can see how we help our customers and what tools we have for this"
                image="./images/svg/laptop-money.svg"
                imageMob="./images/svg/laptop-money-mob.svg"
                variant="secondary"
            />
            <SolveProblems />
            <section className={clsx(s.whyTriton, 'o-hidden')}>
                <TheeColumnSection
                    columnsData={whyTriton}
                    titleBold="Why choose Triton"
                    titleRest="over the competition?"
                    subtitle="The main principle of our work is to solve our clients' problems"
                />
            </section>
            <section>
                <ImageBlock
                    className={s.imageBlock}
                    id={testimonials[0]?.id}
                    title={testimonials[0]?.title}
                    published={testimonials[0]?.published_at}
                    image={testimonials[0]?.image_url}
                />
            </section>
            <section className={s.tritonFocus}>
                <TheeColumnSection
                    columnsData={tritonFocus}
                    titleBold="Triton is a company that"
                    titleRest="focuses on you"
                    variant="secondary"
                />
            </section>
            <ArticleSection
                titleBold="Check interesting articles"
                titleRest="about financial solutions on our blog"
                subtitle="We also have useful materials on financial literacy and communication with creditors"
                numberOfArticles={3}
                linkTo={ROUTES.blog}
                linkName="Visit our blog"
                articles={articles}
            />
            <CalculatorSection />
        </>
    );
};

export default WeHelp;
