import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ROUTES } from '../../constants/constants';
import WithHeader from '../../HOC/WithHeader/WithHeader';
import Loader from '../../components/ui/loader';
import MedicalDebtQuizStepper from '../../components/MedicalDebtQuiz/components/MedicalDebtQuizStepper';
import MedicalDebtQuizEntry from '../../components/MedicalDebtQuiz/components/MedicalDebtQuizEntry';
import s from './MedicalDebtQuizPage.module.scss';
import MedicalDebtQuizMedicAidServiceStep from '../../components/MedicalDebtQuiz/components/MedicalDebtQuizMedicAidServiceStep';
import MedicalDebtQuizCityResidenceStep from '../../components/MedicalDebtQuiz/components/MedicalDebtQuizCityResidenceStep';
import MedicalDebtQuizHouseholdMembersStep from '../../components/MedicalDebtQuiz/components/MedicalDebtQuizHouseholdMembersStep';
import MedicalDebtQuizHouseholdIncomeStep from '../../components/MedicalDebtQuiz/components/MedicalDebtQuizHouseholdIncomeStep';
import MedicalDebtQuizMortgageStep from '../../components/MedicalDebtQuiz/components/MedicalDebtQuizMortgageStep';
import MedicalDebtQuizMedicaidDeniedStep from '../../components/MedicalDebtQuiz/components/MedicalDebtQuizMedicaidDeniedStep';
import MedicalDebtQuizCitizenshipStep from '../../components/MedicalDebtQuiz/components/MedicalDebtQuizCitizenshipStep';
import MedicalDebtQuizPersonalInfoStep from '../../components/MedicalDebtQuiz/components/MedicalDebtQuizPersonalInfoStep';
import MedicalDebtQuizAddressStep from '../../components/MedicalDebtQuiz/components/MedicalDebtQuizAddressStep';
import MedicalDebtQuizHospitalBillStep from '../../components/MedicalDebtQuiz/components/MedicalDebtQuizHospitalBillStep';
import MedicalDebtQuizReviewOptionsStep from '../../components/MedicalDebtQuiz/components/MedicalDebtQuizReviewOptionsStep';
import MedicalDebtQuizNotQualifiedStep from '../../components/MedicalDebtQuiz/components/MedicalDebtQuizNotQualifiedStep';
import MedicalDebtQuizQualifiedStep from '../../components/MedicalDebtQuiz/components/MedicalDebtQuizQualifiedStep';
import MedicalDebtQuizSuccessStep from '../../components/MedicalDebtQuiz/components/MedicalDebtQuizSuccessStep';

const MedicalDebtQuizPage = () => {
    return (
        <motion.section initial="hidden" whileInView="visible" viewport={{ once: true }} className={s.section}>
            <WithHeader themeDark={true} launchButtonText={'Go Home'} launchButtonHref={ROUTES.medicalDebt}>
                <div className="o-hidden">
                    <div className={s.holder}>
                        <Suspense fallback={<Loader />}>
                            <Routes>
                                <Route index element={<MedicalDebtQuizEntry />} />
                                <Route
                                    path={'/*'}
                                    element={
                                        <>
                                            <MedicalDebtQuizStepper />
                                            <Routes>
                                                <Route
                                                    path={ROUTES.medicaidService}
                                                    element={<MedicalDebtQuizMedicAidServiceStep />}
                                                />
                                                <Route
                                                    path={ROUTES.cityResidence}
                                                    element={<MedicalDebtQuizCityResidenceStep />}
                                                />
                                                <Route
                                                    path={ROUTES.householdMembers}
                                                    element={<MedicalDebtQuizHouseholdMembersStep />}
                                                />
                                                <Route
                                                    path={ROUTES.householdIncome}
                                                    element={<MedicalDebtQuizHouseholdIncomeStep />}
                                                />
                                                <Route
                                                    path={ROUTES.mortgage}
                                                    element={<MedicalDebtQuizMortgageStep />}
                                                />
                                                <Route
                                                    path={ROUTES.deniedMedicAid}
                                                    element={<MedicalDebtQuizMedicaidDeniedStep />}
                                                />
                                                <Route
                                                    path={ROUTES.citizenship}
                                                    element={<MedicalDebtQuizCitizenshipStep />}
                                                />
                                                <Route
                                                    path={ROUTES.medicalDebtQuizPersonalInfo}
                                                    element={<MedicalDebtQuizPersonalInfoStep />}
                                                />
                                                <Route
                                                    path={ROUTES.medicalDebtQuizAddress}
                                                    element={<MedicalDebtQuizAddressStep />}
                                                />
                                                <Route
                                                    path={ROUTES.billCopy}
                                                    element={<MedicalDebtQuizHospitalBillStep />}
                                                />
                                                <Route
                                                    path={ROUTES.reviewOptions}
                                                    element={<MedicalDebtQuizReviewOptionsStep />}
                                                />
                                                <Route
                                                    path={ROUTES.notQualified}
                                                    element={<MedicalDebtQuizNotQualifiedStep />}
                                                />
                                                <Route
                                                    path={ROUTES.qualified}
                                                    element={<MedicalDebtQuizQualifiedStep />}
                                                />
                                                <Route path={ROUTES.success} element={<MedicalDebtQuizSuccessStep />} />
                                            </Routes>
                                        </>
                                    }
                                ></Route>
                            </Routes>
                        </Suspense>
                    </div>
                </div>
            </WithHeader>
        </motion.section>
    );
};

export default MedicalDebtQuizPage;
