import clsx from 'clsx';
import { motion } from 'framer-motion';
import styles from './Presentation.module.scss';
import { RESULTS } from './constants';
import { fadeInLeft, fadeInRight, fadeInUp } from '../../../../helpers/utils/animations';
import Container from '../../../../components/Container';
import LinkButton from '../../../../components/LinkButton';

const Presentation = ({ executeScroll }) => {
    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={clsx(styles.presentation, 'o-hidden')}
        >
            <Container>
                <div className={styles.holder}>
                    <div className={styles.left}>
                        <motion.h2 custom={1} variants={fadeInLeft}>
                            Triton has helped consumers find trusted <strong>debt and credit solutions</strong> for
                            years
                        </motion.h2>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className={styles.values}
                        >
                            {RESULTS.map((item, i) => (
                                <motion.div custom={i + 1} variants={fadeInUp} key={i} className={styles.number}>
                                    <p className={styles.quantity}>
                                        {item.num}
                                        {item.plus && <span>+</span>}
                                        {item.star && (
                                            <span>
                                                <img src="./images/blue-star.svg" alt="star" />
                                            </span>
                                        )}
                                    </p>
                                    <p className={styles.helperText}>{item.text}</p>
                                </motion.div>
                            ))}
                            <motion.div custom={4} variants={fadeInUp} className={styles.number}>
                                <p className={styles.quantity}>
                                    <img src="./images/bbb.svg" alt="BBB accredited logo" />
                                </p>
                                <p className={styles.helperText}>BBB accredited third-party providers</p>
                            </motion.div>
                        </motion.div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.rightContent}>
                            <motion.h2 custom={1} variants={fadeInRight}>
                                One simple mission, <strong>two essential services </strong>
                            </motion.h2>
                            <motion.p className={styles.rightSubtitle} custom={2} variants={fadeInRight}>
                                At Triton, we are dedicated to helping American consumers achieve financial stability
                                and freedom. We help consumers find trusted providers that focus on two essential
                                services designed to address your financial needs: debt consolidation and credit repair.
                            </motion.p>
                            <motion.div custom={3} variants={fadeInRight}>
                                <LinkButton hasIcon noHelperText={false} />
                            </motion.div>
                        </div>
                    </div>
                </div>
            </Container>
        </motion.section>
    );
};

export default Presentation;
