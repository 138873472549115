import { useContext, useEffect, useRef } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { actionsSelector, useQuizStore } from '../../components/Quiz/store';
import { ROUTES } from '../../constants/constants';
import SliderSection from '../../components/SliderSection';
import DoubleSection from '../../components/DoubleSection/DoubleSection';
import TopContentBlock from '../../components/TopContentBlock/TopContentBlock';
import CalculatorSection from '../../components/CalculatorSection/CalculatorSection';
import ArticleSection from '../../components/Article/ArticleSection';
import s from './HomePage.module.scss';
import ImageBlock from '../../components/ImageBlock/ImageBlock';
import { AppContext } from '../../App';
import Presentation from './components/Presentation';
import { HOME_DOUBLE_SECTIONS_ITEMS, HOME_PAGE_REVIEWS } from './constants';
import DebtTypes from '../../components/DebtTypes/DebtTypes';
import TripleSection from '../../components/TripleSection/TripleSection';

const HomePage = () => {
    const { articles, testimonials } = useContext(AppContext);
    const quizActions = useQuizStore(useShallow(actionsSelector));

    const videoRef = useRef(null);

    const executeScroll = () => videoRef.current.scrollIntoView({ behavior: 'smooth' });

    useEffect(() => {
        quizActions.cleanUp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TopContentBlock
                titleBold="Helping Americans"
                titleRest="achieve financial greatness"
                subtitle={
                    <>
                        Whether you need to <strong>consolidate debt</strong> or{' '}
                        <strong>increase your credit score</strong>, let Triton match you with the right solution.
                    </>
                }
                image="./images/home-main.svg"
                imageMob="./images/home-mainMob.svg"
                blueBlock
                button
                followUs
                noHelperText={false}
            />
            <Presentation executeScroll={executeScroll} />
            <SliderSection
                titleBold={<>see what other consumers have to say.</>}
                titleRest={<>Don&apos;t take our word for it,&nbsp;</>}
                sliderData={HOME_PAGE_REVIEWS}
            />
            <section className={s.doubleSections}>
                <h2 className={s.sectionTitle}>
                    Our <strong>Services</strong>
                </h2>
                {HOME_DOUBLE_SECTIONS_ITEMS.map((props, index) => (
                    <DoubleSection key={index} {...props} />
                ))}
            </section>
            <DebtTypes />
            <TripleSection
                title={
                    <>
                        How to <strong>get started?</strong>
                    </>
                }
                subtitle="See how three simple steps can transform your life financially."
            />
            <section ref={videoRef}>
                <ImageBlock
                    className={s.imageBlock}
                    id={testimonials[0]?.id}
                    title={testimonials[0]?.title}
                    published={testimonials[0]?.published_at}
                    image={testimonials[0]?.image_url}
                />
            </section>
            <ArticleSection
                titleBold="Check interesting articles"
                titleRest="about financial solutions on our blog"
                subtitle="We also have useful materials on financial literacy and communication with creditors"
                numberOfArticles={3}
                linkTo={ROUTES.blog}
                linkName="Go to our blog"
                articles={articles}
            />
            <CalculatorSection />
        </>
    );
};

export default HomePage;
