import Container from '../../../../components/Container';
import s from './Partners.module.scss';
import { motion } from 'framer-motion';
import { fadeInLeft } from '../../../../helpers/utils/animations';
import { PARTNERS_LOGOS } from './constants';

const Partners = () => {
    return (
        <motion.section className={s.wrap} initial="hidden" whileInView="visible" viewport={{ once: true }}>
            <Container className={s.container}>
                <header className={s.header}>
                    <motion.h2 className={s.title} custom={1} variants={fadeInLeft}>
                        Who <strong>our lending partners are?</strong>
                    </motion.h2>
                    <motion.p className={s.subtitle} custom={2} variants={fadeInLeft}>
                        We work only with the best companies in the U.S. financial industry, so we can be responsible
                        for the quality of services we provide
                    </motion.p>
                </header>
                <ul className={s['images-wrap']}>
                    {PARTNERS_LOGOS.map((partnerImageSrc, index) => {
                        return (
                            <li key={index}>
                                <motion.img
                                    custom={index + 1}
                                    variants={fadeInLeft}
                                    src={partnerImageSrc}
                                    alt="Partner logo"
                                />
                            </li>
                        );
                    })}
                </ul>
            </Container>
        </motion.section>
    );
};

export default Partners;
