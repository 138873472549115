import { motion } from 'framer-motion';
import { useShallow } from 'zustand/react/shallow';
import clsx from 'clsx';
import { actionsSelector, useMedicalDebtQuizStore } from '../../store';
import { fadeInUp } from '../../../../helpers/utils/animations';
import { ROUTES } from '../../../../constants/constants';
import { MEDICAL_DEBT_QUIZ_TITLES } from '../../constants';
import Container from '../../../Container';
import IconDone from '../../../ui/Icons/IconDone';
import Button from '../../../ui/Button';
import s from './MedicalDebtQuizEntry.module.scss';
import { useEffect } from 'react';

const MedicalDebtQuizEntry = () => {
    const { cleanUp } = useMedicalDebtQuizStore(useShallow(actionsSelector));

    useEffect(() => {
        cleanUp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container className={s.wrap}>
            <div className={s.title}>
                <motion.h1 custom={1} variants={fadeInUp}>
                    <strong>Get help</strong> with your medical debt today
                </motion.h1>
            </div>

            <div className={s.subtitle}>
                <motion.h4 custom={2} variants={fadeInUp}>
                    Get connected with expert medical debt advisors.
                </motion.h4>
            </div>

            <motion.div
                className={clsx(s.holder, 'steps-wrap')}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
            >
                {MEDICAL_DEBT_QUIZ_TITLES.map((title, i) => (
                    <motion.div custom={i + 1} variants={fadeInUp} className={s.card} key={i}>
                        <div className={s.cardHeader}>
                            <IconDone className={s.icon} />
                            <div className={s.cardTitle}>
                                <h3>
                                    Step&nbsp;<span>#{i + 1}</span>
                                </h3>
                            </div>
                        </div>
                        <div className={s.cardDesc}>
                            <h5>{title}</h5>
                        </div>
                    </motion.div>
                ))}
            </motion.div>
            <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }}>
                <motion.div variants={fadeInUp} className={s.action}>
                    <Button
                        className={s.cta}
                        href={`${ROUTES.medicalDebt}/${ROUTES.questionnaire}/${ROUTES.medicaidService}`}
                        responsive
                    >
                        Start the questionnaire
                    </Button>
                </motion.div>
            </motion.div>
        </Container>
    );
};

export default MedicalDebtQuizEntry;
