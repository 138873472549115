export const RESULTS = [
    {
        num: '20M',
        customLabel: '+',
        text: 'Americans have medical debt',
    },
    {
        num: '$10M',
        customLabel: '+',
        text: 'Medical Bankrupcies in the last 20 years',
    },
    {
        num: '57',
        plus: false,
        customLabel: '%',
        text: 'Received surprise medical bill',
    },
    {
        num: '+4.5',
        star: true,
        text: 'Excellent trustpilot client rating',
    },
];
