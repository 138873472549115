import { useShallow } from 'zustand/react/shallow';
import { useArrayToHubspotAsQuery } from '../../../../../hooks/api/useTritonFormsApi';
import { allFieldsSelector, useCreditRestorationQuizStore } from '../../../store';

export const useCreditRestorationArrayToHubspot = () => {
    const { firstName, lastName, address, city, state, zip, email } = useCreditRestorationQuizStore(
        useShallow(allFieldsSelector)
    );

    return useArrayToHubspotAsQuery({
        firstname: firstName,
        lastname: lastName,
        address,
        city,
        state,
        zip,
        email,
    });
};
