import { forwardRef, memo, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { useForwardRef } from '../../hooks/useForwardRef';
import Typography from '../Typography';
import Label from '../ui/Label';
import Error from '../ui/Error';
import s from './FilePicker.module.css';

/**
 *
 * @param {{
 *  label?: string;
 *  fileName?: string;
 *  placeholder?: string;
 *  acceptedFormats?: string[];
 *  errorMessage?: string;
 *  onFileChange: (file: File, metadata?: { width: number; height: number }) => void;
 * }} param0
 * @param {React.Ref<HTMLInputElement>} ref
 * @returns
 */
const FilePicker = (
    { className, label = 'Upload your files', fileName, placeholder, acceptedFormats, errorMessage, onFileChange },
    ref
) => {
    const fileInputRef = useForwardRef(ref);

    const browseFilesClickHandler = useCallback(() => {
        fileInputRef.current?.click();
    }, [fileInputRef]);

    const fileChangeHandler = useCallback(
        (event) => {
            if (event.target.files?.length) {
                const file = event.target.files[0];

                console.log(file.name);

                return onFileChange(file);
            }
        },
        [onFileChange]
    );

    useEffect(() => {
        console.log({ fileName });
    }, [fileName]);

    return (
        <div className={clsx(s.wrap, className)}>
            <Label className={s.label}>{label}</Label>
            <div
                className={clsx(s['input-wrap'], 'focus-within-primary', 'truncate', {
                    [s.error]: !!errorMessage,
                })}
            >
                <div
                    className={clsx(s['value-wrap'], 'truncate', {
                        [s.placeholder]: !fileName,
                    })}
                >
                    <Typography className={clsx(s.value, 'truncate')} variant="text-section-sm" asChild>
                        <span>{fileName ?? placeholder ?? 'example.csv'}</span>
                    </Typography>
                </div>
                <button className={s.trigger} type="button" onClick={browseFilesClickHandler}>
                    <img className={s.icon} src="/icons/folder.svg" alt="" />
                    <Typography className={s.title} asChild>
                        <span>Browse</span>
                    </Typography>
                </button>
                <input
                    ref={fileInputRef}
                    className="visually-hidden"
                    type="file"
                    accept={acceptedFormats?.join(',')}
                    onChange={fileChangeHandler}
                />
            </div>
            {!!errorMessage && <Error className={s['error-wrap']}>{errorMessage}</Error>}
        </div>
    );
};

export default memo(forwardRef(FilePicker));
