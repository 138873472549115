export const MEDICAL_DEBT_DOUBLE_SECTIONS_ITEMS = [
    {
        image: './images/hospital.svg',
        imageMob: './images/hospital.svg',
        title: 'Excessive ER Bills',
        titleRest: 'Excessive',
        titleBold: 'ER Bills',
        text: 'See how your overpriced ER visit can be reduced to nothing with the proper help from expert advisors',
        follow: 'button',
        imagePosition: 'left',
    },
    {
        image: './images/tablet-n-pills.svg',
        imageMob: './images/tablet-n-pills.svg',
        title: 'Inflated Prescription Costs',
        titleRest: 'Inflated',
        titleBold: 'Prescription Costs',
        text: 'Learn how you can pay a fraction of the price you`re currently paying for your prescription medicine with the click of a button',
        follow: 'button',
        imagePosition: 'right',
    },
    {
        image: './images/money-scissors.svg',
        imageMob: './images/money-scissors.svg',
        title: 'Unitemized Hospital Invoices',
        titleRest: 'Unitemized',
        titleBold: 'Hospital Invoices',
        text: 'We help you get itemized hospital invoices so we can determine exact prices and work effectively',
        follow: 'button',
        imagePosition: 'left',
    },
];

export const MEDICAL_DEBT_PAGE_REVIEWS = [
    {
        id: 's4',
        rating: 5,
        text: 'They helped save our family THOUSANDS of dollars, a huge financial burden lifted! I cannot thank you enough!',
        name: 'Rachel P.',
        shortName: 'R',
        date: '5 hours ago',
    },
    {
        id: 's5',
        rating: 5,
        text: 'A few weeks ago I received a Cleveland Clinic ER Bill in the amount of $13,046.33. I paid $0!!',
        name: 'Jim V.',
        shortName: 'J',
        date: '1 day ago',
        customColor: '#DF8B0E',
        customBg: 'rgba(223, 139, 14, 0.1)',
    },
    {
        id: 's6',
        rating: 5,
        text: 'My wife`s $55,000 broken hip bill is now sitting below $10,000 and still dropping. Thank you so...',
        name: 'Larry S.',
        shortName: 'L',
        date: '2 hours ago',
        customColor: '#98DB96',
        customBg: 'rgba(152, 219, 150, 0.1)',
    },
];

export const MEDICAL_DEBT_ACCORDION_ITEMS = [
    {
        id: 1,
        title: 'How many hospitals offer free care?',
        info: `3,000 non-profit hospitals of 5,700 hospitals in the US offer free care via a requirement for a financial assistance program (FAP). 
    \nNon-profit hospitals have a federal obligation to provide a charity care program as a part of their federal requirement by the Affordable Care Act and Internal Revenue Service 501(r) requirement. 
    \n2,000 for profit hospitals also have financial assistance policy, bring 5,000 total hospitals to our platform.`,
    },
    {
        id: 2,
        title: 'How many people are eligible for free or discounted care?',
        info: `Over 100,000,000 people are estimated to qualify for free or discounted care at non-profit hospitals across the US.`,
    },
    {
        id: 3,
        title: 'Will this keep me out of collections?',
        info: `Yes. While applying for financial assistance, the hospital is not permitted to send a patient to collections until the financial assistance application has a final determination that is denied. 
    \nIn addition, our expert advisors know healthcare consumer laws. We have other effective techniques we use to keep hospitals from participating in predatory billing habits.`,
    },
    {
        id: 4,
        title: 'How does this help the Employee?',
        info: `Our advisors can help erase the patient deductible and coinsurance, or in layman’s terms, the out-of-pocket expense not covered by insurance. 
    \nFor members who experience a denied claim, an out-of-network claim or do not have insurance, we assist to erase as much of the entire hospital bill possible. 
    \nNote that after erasing a deductible for those that qualify, that person can get care for the rest of the calendar year with no out-of-pocket having met his or her deductible without paying his or her deductible.`,
    },
    {
        id: 5,
        title: 'How does this help the Employer?',
        info: `We help keep claims above the stop loss attachment point at or near the attachment point to keep stop loss premiums lower. We also help save health plan money for the average 8% of health plan dollars that are out-of-network. For out-of-network claims, there is no health insurance company rule that the hospital must be paid what the insurance company dictates. 
    \nHospital Bill Eraser can seek free care for the employee and the employer benefits also with no out-of-pocket expense. This can greatly reduce the employer’s health plan spend for out-of-network claims. 
    \nBy offering a better health benefit to employees with less out-of-pocket care costs, we help the employer with recruitment and retention. We can also alleviate human resource (HR) and benefits department burden by having them direct all hospital bill and explanation of benefits (EOB) questions to us.`,
    },
];
