import { useShallow } from 'zustand/react/shallow';
import { allFieldsSelector, useMedicalDebtQuizStore } from '../../../store';
import { useMemo } from 'react';
import { BOOLEAN_OPTIONS_IDS } from '../../../../../constants/constants';

export const useMedicalDebtQuizQualification = () => {
    const { medicaidService, cityResidence, citizenship } = useMedicalDebtQuizStore(useShallow(allFieldsSelector));

    const isQualified = useMemo(() => {
        const isMedicaidServiceQualified = medicaidService === BOOLEAN_OPTIONS_IDS.no;
        const isCityResidenceQualified = cityResidence === BOOLEAN_OPTIONS_IDS.yes;
        const isCitizenshipQualified = citizenship === BOOLEAN_OPTIONS_IDS.yes;

        return isMedicaidServiceQualified && isCityResidenceQualified && isCitizenshipQualified;
    }, [medicaidService, cityResidence, citizenship]);

    return { isQualified };
};
