import React, { useContext } from 'react';
import { AppContext } from '../../App';
import { ROUTES } from '../../constants/constants';
import { CREDIT_SCORE_SOLUTIONS_ACCORDION_ITEMS, CREDIT_SCORE_SOLUTIONS_REVIEWS } from './constants';
import HiroSection from '../../components/CreditScoreSolutions/components/HiroSection';
import Partnership from '../../components/Partnership';
import ArticleSection from '../../components/Article/ArticleSection';
import CalculatorSection from '../../components/CalculatorSection/CalculatorSection';
import CreditScoreOffers from '../../components/CreditScoreSolutions/components/CreditScoreOffers/CreditScoreOffers';
import FinancialJourney from '../../components/CreditScoreSolutions/components/FinancialJourney';
import CreditRestoration from '../../components/CreditScoreSolutions/components/CreditRestoration';
import SliderSection from '../../components/SliderSection';
import ExpertiseInAction from '../../components/CreditScoreSolutions/components/ExpertiseInAction/ExpertiseInAction';
import ImageAndTextSections from '../../components/CreditScoreSolutions/components/ImageAndTextSections/ImageAndTextSections';
import Faq from '../../components/Faq';

const CreditScoreSolutions = () => {
    const { articles } = useContext(AppContext);

    return (
        <div>
            <HiroSection
                titleBold="Credit Score Solutions"
                titleRest="for Everyone"
                subtitle="Our expert advisors work with you to navigate the complexities of credit improvement, ensuring a brighter financial tomorrow. Begin your journey to credit confidence today!"
                image="./images/svg/credit-score.svg"
                imageMob="./images/svg/credit-score.svg"
                blueBlock={true}
                buttonText="Get started"
                button={true}
                poweredBy
                noHelperText
            />
            <Partnership />
            <FinancialJourney />
            <CreditRestoration />
            <SliderSection
                titleBold="credit success with Triton"
                titleRest="Hear our clients' stories of "
                subtitle="Don't take our word for it, see what our clients have to say"
                sliderData={CREDIT_SCORE_SOLUTIONS_REVIEWS}
            />
            <ExpertiseInAction />
            <ImageAndTextSections />
            <CreditScoreOffers />
            <Faq
                title={
                    <>
                        Expert answers to <strong>your credit repair queries</strong>
                    </>
                }
                questions={CREDIT_SCORE_SOLUTIONS_ACCORDION_ITEMS}
            />
            <ArticleSection
                titleBold="Check interesting articles"
                titleRest="about debt consolidation on our blog"
                subtitle="We also have useful materials on financial literacy and communication with creditors"
                numberOfArticles={articles.length}
                linkTo={ROUTES.blog}
                articles={articles}
                linkName="Go to our blog"
            />
            <CalculatorSection
                title={
                    <>
                        Ready <strong>to transform</strong> your credit?
                    </>
                }
                linkTo={`/${ROUTES.creditRestorationQuiz}`}
            />
        </div>
    );
};

export default CreditScoreSolutions;
