import { ROUTES } from '../../../../constants/constants';

export const MEDICAL_DEBT_QUIZ_STEPS = {
    [ROUTES.medicaidService]: {
        step: 1,
        step_name: ROUTES.medicaidService,
        label: 'Your coverage info',
    },
    [ROUTES.cityResidence]: {
        step: 2,
        step_name: ROUTES.cityResidence,
        label: 'Your city info',
    },
    [ROUTES.householdMembers]: {
        step: 3,
        step_name: ROUTES.householdMembers,
        label: 'Your household members info',
    },
    [ROUTES.householdIncome]: {
        step: 4,
        step_name: ROUTES.householdIncome,
        label: 'Your income info',
    },
    [ROUTES.mortgage]: {
        step: 5,
        step_name: ROUTES.mortgage,
        label: 'Your mortgage info',
    },
    [ROUTES.deniedMedicAid]: {
        step: 6,
        step_name: ROUTES.deniedMedicAid,
        label: 'Your applies info',
    },
    [ROUTES.citizenship]: {
        step: 7,
        step_name: ROUTES.citizenship,
        label: 'Your citizenship info',
    },
    [ROUTES.medicalDebtQuizPersonalInfo]: {
        step: 8,
        step_name: ROUTES.medicalDebtQuizPersonalInfo,
        label: 'Help Us Make Sure It`s You',
    },
    [ROUTES.medicalDebtQuizAddress]: {
        step: 9,
        step_name: ROUTES.medicalDebtQuizAddress,
        label: 'Help Us Make Sure It`s You',
    },
    [ROUTES.billCopy]: {
        step: 10,
        step_name: ROUTES.billCopy,
        label: 'Your hospital bill info',
    },
    [ROUTES.notQualified]: {
        step: 11,
        step_name: ROUTES.notQualified,
        label: 'Review Your Options',
    },
    [ROUTES.qualified]: {
        step: 11,
        step_name: ROUTES.qualified,
        label: 'Review Your Options',
    },
    [ROUTES.reviewOptions]: {
        step: 11,
        step_name: ROUTES.reviewOptions,
        label: 'Review Your Options',
    },
    [ROUTES.success]: {
        step: 12,
        step_name: ROUTES.success,
        label: 'Review Your Options',
    },
};
