import { motion } from 'framer-motion';
import clsx from 'clsx';
import { fadeInLeft, fadeInRight } from '../../../../helpers/utils/animations';
import { ROUTES } from '../../../../constants/constants';
import WithHeader from '../../../../HOC/WithHeader';
import LinkButton from '../../../LinkButton';
import Container from '../../../Container';
import s from './HiroSection.module.scss';

const HiroSection = ({
    titleBold,
    titleRest,
    variant,
    subtitle,
    image,
    button,
    followUs,
    noHelperText,
    buttonText,
    poweredBy,
    blueBlock,
    imageMob,
}) => {
    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={clsx(s.topContent, s[variant], 'blueBlock')}
        >
            <WithHeader>
                <div className={clsx(s.wrap, 'o-hidden')}>
                    <Container>
                        <div className={s.holder}>
                            <div className={s.content}>
                                <motion.h1 custom={1} variants={fadeInLeft}>
                                    <strong>{titleBold}</strong> {titleRest}
                                </motion.h1>
                                <motion.p custom={2} variants={fadeInLeft} className={s.text}>
                                    {subtitle}
                                </motion.p>
                                {button && (
                                    <motion.div custom={3} variants={fadeInLeft}>
                                        <LinkButton
                                            linkTo={`/${ROUTES.creditRestorationQuiz}`}
                                            text={buttonText}
                                            variant="fourth"
                                            hasIcon
                                            noHelperText={noHelperText ?? true}
                                            blueBlock={blueBlock}
                                        />
                                    </motion.div>
                                )}
                            </div>
                            {/* {followUs && (
                                <div className={s.findUs}>
                                    <p>As seen in:</p>
                                    <div className={s.icons}>
                                        <img src="./images/ny.svg" alt="The New Yorker" />
                                        <img src="./images/nyt.svg" alt="The New York Times" />
                                        <img src="./images/bbc.svg" alt="BBC News" />
                                    </div>
                                </div>
                            )} */}
                            {poweredBy && (
                                <div className={s.poweredBy}>
                                    <p>Powered by:</p>
                                    <div className={s.icons}>
                                        <img src="./images/svg/uca.svg" alt="UCA" />
                                    </div>
                                </div>
                            )}
                            <div className={s.image}>
                                {imageMob ? (
                                    <div>
                                        <motion.img
                                            custom={2}
                                            variants={fadeInRight}
                                            src={image}
                                            alt={titleBold}
                                            fetchpriority="high"
                                            className={s.desktopImg}
                                        />
                                        <motion.img
                                            custom={2}
                                            variants={fadeInRight}
                                            src={imageMob}
                                            alt={titleBold}
                                            fetchpriority="high"
                                            className={s.mobileImg}
                                        />
                                    </div>
                                ) : (
                                    <motion.img
                                        custom={2}
                                        variants={fadeInRight}
                                        src={image}
                                        alt={titleBold}
                                        fetchpriority="high"
                                        className={s.singleImage}
                                    />
                                )}
                            </div>
                        </div>
                    </Container>
                </div>
            </WithHeader>
        </motion.div>
    );
};

export default HiroSection;
