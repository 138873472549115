import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { fadeInLeft, fadeInUp } from '../../helpers/utils/animations';
import ButtonFollow from '../ui/ButtonFollow/ButtonFollow';
import Container from '../Container/Container';
import Article from './Article';
import s from './Article.module.scss';

const sortLinks = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Financial literacy' }, // Debt consolidation
    { id: 2, name: 'Communication' }, // Personal loans
    { id: 3, name: 'Other' }, // Triton news
];

const ArticleSection = ({
    titleBold,
    titleRest,
    subtitle,
    numberOfArticles,
    linkTo,
    linkName,
    clickHandler,
    articles,
    variant,
}) => {
    const [sortedArticles, setSortedArticles] = useState(articles);
    const [activeSection, setActiveSection] = useState(0);
    const location = useLocation().pathname.slice(1);

    const sortingHandler = (id) => {
        setActiveSection(id);
        if (+id === 0) {
            return setSortedArticles(articles);
        }
        setSortedArticles(articles.filter((item) => Number(item.sectionId) === id));
    };

    useEffect(() => {
        setSortedArticles(articles);
    }, [articles]);

    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={clsx(s.articles, s[variant], 'o-hidden')}
        >
            <Container>
                <motion.h2 className={s.title} custom={1} variants={fadeInLeft}>
                    <strong>{titleBold}</strong> {titleRest}
                </motion.h2>

                {subtitle && (
                    <motion.p custom={2} variants={fadeInLeft} className={s.subtitle}>
                        {subtitle}
                    </motion.p>
                )}

                {location === 'blog' && (
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} className={s.navWrap}>
                        <motion.ul variants={fadeInUp} className={clsx(s.navButtons, 'scrollbar-hidden')}>
                            {sortLinks.map((link) => (
                                <li className={s.navItem} key={link.id}>
                                    <button
                                        className={clsx(s.navLink, {
                                            [s.active]: activeSection === link.id,
                                        })}
                                        onClick={() => sortingHandler(link.id)}
                                    >
                                        {link.name}
                                    </button>
                                </li>
                            ))}
                        </motion.ul>
                    </motion.div>
                )}

                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }}>
                    {sortedArticles && (
                        <motion.div variants={fadeInUp} className={s.content}>
                            {sortedArticles.slice(0, numberOfArticles).map((article, i) => (
                                <Article key={article.id} {...article} />
                            ))}
                        </motion.div>
                    )}
                </motion.div>

                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }}>
                    {numberOfArticles < sortedArticles.length && (
                        <motion.div variants={fadeInUp} className={s.content}>
                            {linkTo ? (
                                <ButtonFollow href={linkTo}>{linkName}</ButtonFollow>
                            ) : (
                                <ButtonFollow clickHandler={clickHandler}>{linkName}</ButtonFollow>
                            )}
                        </motion.div>
                    )}
                </motion.div>
                <motion.footer className={s.footer}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }}>
                        <ButtonFollow href={linkTo}>{linkName}</ButtonFollow>
                    </motion.div>
                </motion.footer>
            </Container>
        </motion.section>
    );
};

export default ArticleSection;
