import { memo } from 'react';
import classes from './Label.module.scss';
import clsx from 'clsx';

const Label = ({ component = 'label', htmlFor, className, children }) => {
    const CustomTag = component;
    return (
        <CustomTag htmlFor={htmlFor} className={clsx(classes.label, className)}>
            {children}
        </CustomTag>
    );
};

export default memo(Label);
