import { useContext, useRef } from 'react';
// import { actionsSelector, useQuizStore } from '../../components/Quiz/store';
import { ROUTES } from '../../constants/constants';
import SliderSection from '../../components/SliderSection';
import DoubleSection from '../../components/DoubleSection/DoubleSection';
import TopContentBlock from '../../components/TopContentBlock/TopContentBlock';
import CalculatorSection from '../../components/CalculatorSection/CalculatorSection';
import ArticleSection from '../../components/Article/ArticleSection';
import styles from './MedicalDebtPage.module.scss';
import ImageBlock from '../../components/ImageBlock/ImageBlock';
import { AppContext } from '../../App';
import Presentation from './components/Presentation';
import {
    MEDICAL_DEBT_ACCORDION_ITEMS,
    MEDICAL_DEBT_DOUBLE_SECTIONS_ITEMS,
    MEDICAL_DEBT_PAGE_REVIEWS,
} from './constants';
import Faq from '../../components/Faq/Faq';
import TripleSection from './components/TripleSection';

const MedicalDebtPage = () => {
    const { articles, testimonials } = useContext(AppContext);
    // const quizActions = useQuizStore(actionsSelector);

    const videoRef = useRef(null);

    const executeScroll = () => videoRef.current.scrollIntoView({ behavior: 'smooth' });

    // useEffect(() => {
    //     quizActions.cleanUp();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <>
            <TopContentBlock
                titleBold="Start Conquering Medical Debt"
                titleRest=""
                subtitle="Hospitals' predatory billing strategies are leaving patients buried in unexpected medical debt. We provide the most effective solutions to find the relief you deserve."
                image="./images/medical-debt-hiro.svg"
                imageClassName={styles['top-content-image']}
                blueBlock
                linkTo={`${ROUTES.medicalDebt}/${ROUTES.questionnaire}`}
                button
                followUs
            />
            <Presentation executeScroll={executeScroll} />
            <SliderSection
                titleBold="for you to succeed"
                titleRest="We show you the best options"
                subtitle="Don't take our word for it, see what our clients have to say"
                sliderData={MEDICAL_DEBT_PAGE_REVIEWS}
            />
            <div className={styles.doubleSections}>
                {MEDICAL_DEBT_DOUBLE_SECTIONS_ITEMS.map((props, i) => (
                    <DoubleSection
                        key={i}
                        noHelperText
                        {...props}
                        linkTo={`${ROUTES.medicalDebt}/${ROUTES.questionnaire}`}
                    />
                ))}
            </div>
            <TripleSection />
            <section ref={videoRef}>
                <ImageBlock
                    title="Learn how Rachel P paid next to nothing after an 8-day hospital stay"
                    published={new Date('2024-02-23')}
                    image={testimonials[0]?.image_url}
                    customHref="https://docs.google.com/document/d/188UNfJsJHRX94kqyP7-7oKEVdFLjg-RdG74CT4tD7ic/edit?usp=sharing"
                    id={testimonials[0]?.id}
                />
            </section>
            <div className={styles['faq-wrap']}>
                <Faq
                    title={
                        <>
                            <strong>Frequently</strong> asked questions
                        </>
                    }
                    questions={MEDICAL_DEBT_ACCORDION_ITEMS}
                />
            </div>
            <ArticleSection
                titleBold="Check interesting articles"
                titleRest="about medical financial solutions on our blog"
                subtitle="We also have useful materials on financial literacy and communication with creditors"
                numberOfArticles={3}
                linkTo={ROUTES.blog}
                linkName="Visit our blog"
                articles={articles}
            />
            <CalculatorSection noHelperText linkTo={`${ROUTES.medicalDebt}/${ROUTES.questionnaire}`} />
        </>
    );
};

export default MedicalDebtPage;
