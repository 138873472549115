import { useContext, useEffect, useRef } from 'react';
import { actionsSelector, useQuizStore } from '../../components/Quiz/store';
import { ROUTES } from '../../constants/constants';
import SliderSection from '../../components/SliderSection';
import { dataAccordion } from '../../config/dataAccordion';
import DoubleSection from '../../components/DoubleSection/DoubleSection';
import TopContentBlock from '../../components/TopContentBlock/TopContentBlock';
import CalculatorSection from '../../components/CalculatorSection/CalculatorSection';
import ArticleSection from '../../components/Article/ArticleSection';
import s from './DebtSolutionsPage.module.scss';
import ImageBlock from '../../components/ImageBlock/ImageBlock';
import { AppContext } from '../../App';
import Presentation from '../../components/Presentation/Presentation';
import { CARDS, HOME_DOUBLE_SECTIONS_ITEMS, HOME_PAGE_REVIEWS } from './constants';
import DebtTypes from '../../components/DebtTypes/DebtTypes';
import TripleSection from '../../components/TripleSection/TripleSection';
import Faq from '../../components/Faq/Faq';
import { useShallow } from 'zustand/react/shallow';

const DebtSolutionsPage = () => {
    const { articles, testimonials } = useContext(AppContext);
    const quizActions = useQuizStore(useShallow(actionsSelector));

    const videoRef = useRef(null);

    const executeScroll = () => videoRef.current.scrollIntoView({ behavior: 'smooth' });

    useEffect(() => {
        quizActions.cleanUp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TopContentBlock
                titleBold="Quick & easy ways"
                titleRest="to pay off your debt"
                subtitle={
                    <>
                        Debt solutions can be hard to navigate, especially in an over saturated market. Triton hopes to
                        relieve some of that stress and to provide stability and security.
                    </>
                }
                image="./images/debt-man-run.svg"
                imageClassName={s['top-content-image']}
                blueBlock
                button
                followUs
                noHelperText={false}
            />
            <Presentation
                title={
                    <>
                        Triton has been a <strong>leader in debt solutions</strong> for years
                    </>
                }
                executeScroll={executeScroll}
                buttonProps={{
                    variant: 'play',
                    className: s.cta,
                }}
            />
            <SliderSection
                titleBold={<>for you to succeed</>}
                titleRest={<>We show you the best options&nbsp;</>}
                subtitle="Don't take our word for it, see what our clients have to say"
                sliderData={HOME_PAGE_REVIEWS}
            />
            <div className={s.doubleSections}>
                {HOME_DOUBLE_SECTIONS_ITEMS.map((props, i) => (
                    <DoubleSection key={i} {...props} />
                ))}
            </div>
            <DebtTypes
                title={
                    <>
                        <strong>What debt types</strong>&nbsp;can we help you with?
                    </>
                }
            />
            <TripleSection options={CARDS} />
            <section ref={videoRef}>
                <ImageBlock
                    title={testimonials[0]?.title}
                    published={testimonials[0]?.published_at}
                    image={testimonials[0]?.image_url}
                    id={testimonials[0]?.id}
                />
            </section>
            <Faq questions={dataAccordion} />
            <ArticleSection
                titleBold="Check interesting articles"
                titleRest="about financial solutions on our blog"
                subtitle="We also have useful materials on financial literacy and communication with creditors"
                numberOfArticles={3}
                linkTo={ROUTES.blog}
                linkName="Go to our blog"
                articles={articles}
            />
            <CalculatorSection />
        </>
    );
};

export default DebtSolutionsPage;
