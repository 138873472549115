import { useShallow } from 'zustand/react/shallow';
import { useController, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { medicalDebtQuizPersonalInfoSchema } from '../schemas';
import { contactDetailsSelector, useMedicalDebtQuizStore } from '../../../store';

export const useMedicalDebtQuizPersonalInfoForm = () => {
    const { firstName, lastName, email, phone } = useMedicalDebtQuizStore(useShallow(contactDetailsSelector));

    const { control, ...rest } = useForm({
        resolver: zodResolver(medicalDebtQuizPersonalInfoSchema),
        defaultValues: {
            firstName,
            lastName,
            email,
            phone,
        },
        mode: 'all',
    });

    const firstNameController = useController({
        control,
        name: 'firstName',
    });
    const lastNameController = useController({
        control,
        name: 'lastName',
    });
    const emailController = useController({
        control,
        name: 'email',
    });
    const phoneController = useController({
        control,
        name: 'phone',
    });

    return {
        firstNameController,
        lastNameController,
        emailController,
        phoneController,
        ...rest,
    };
};
