import React from 'react';
import ButtonsGroup from '../../components/ButtonsGroup/ButtonsGroup';
import s from './AgreementsPage.module.scss';

const Terms = () => {
    return (
        <div className={s.content}>
            <h1 className="h1">Terms of Use</h1>
            <h4 className="h4">Effective Date: January 1, 2025</h4>

            <h3 className="h3">1. Acceptance of Terms</h3>
            <p>
                Welcome to the Triton Finance website (&quot;Website&quot;). These <strong>Terms of Use</strong>{' '}
                (&quot;Terms&quot;) govern your access to and use of the Website. By accessing or using the Website, you
                acknowledge that you have read, understood, and agreed to be bound by these Terms.
            </p>
            <p className={s.bold}>🔹 IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT USE THE WEBSITE.</p>
            <p>
                We reserve the right to update these Terms at any time. Your continued use of the Website after
                modifications constitutes your acceptance of the updated Terms.
            </p>

            <h3>2. Eligibility & Use of the Website</h3>
            <h5>a. Eligibility</h5>
            <ul className={s.list} data-list-type="diamonds">
                <li>
                    You must be at least <strong>18 years old</strong> to use this Website.
                </li>
                <li>By using the Website, you represent and warrant that you meet this age requirement.</li>
            </ul>
            <h5>b. Permitted Use</h5>
            <ul className={s.list} data-list-type="checkboxes">
                <li>
                    The Website is provided <strong>for informational purposes only</strong>.
                </li>
                <li>
                    You may use the Website to:
                    <ul className={s.list} data-list-type="disc">
                        <li>
                            Learn about <strong>debt consolidation</strong> and <strong>credit repair services</strong>{' '}
                            offered by our third-party providers.
                        </li>
                        <li>
                            Access <strong>educational financial resources</strong>.
                        </li>
                        <li>
                            Complete our <strong>customer questionnaire</strong> to check eligibility for services.
                        </li>
                        <li>Contact us for support or inquiries.</li>
                    </ul>
                </li>
            </ul>
            <p className={s.bold}>🚫 Any other use without our written permission is strictly prohibited.</p>
            <h5>c. Prohibited Activities</h5>
            <p>By using the Website, you agree not to:</p>
            <ul className={s.list} data-list-type="crosses">
                <li>
                    Violate any <strong>laws, regulations, or third-party rights</strong>.
                </li>
                <li>
                    Use the Website for <strong>fraudulent, misleading, or deceptive purposes</strong>.
                </li>
                <li>
                    Attempt to <strong>fraudulent, misleading, or deceptive purposes</strong> to our systems or servers.
                </li>
                <li>
                    Introduce <strong>viruses, malware, or harmful code</strong>.
                </li>
                <li>
                    Use automated scripts, scraping tools, or bots to <strong>extract data</strong> from our Website.
                </li>
            </ul>

            <h3 className="h3">Account Registration (If Applicable)</h3>
            <p>Certain features of the Website may require account registration. If you create an account:</p>
            <ul>
                <li>
                    You must provide <strong>accurate and complete</strong> information.
                </li>
                <li>
                    You are responsible for maintaining the <strong>security of your account credentials</strong>.
                </li>
                <li>
                    You agree to notify us <strong>immediately</strong> if you suspect unauthorized account access.
                </li>
            </ul>
            <p className={s.bold}>
                🚨 We are not responsible for unauthorized account activity if you fail to secure your login
                information.
            </p>

            <h3>4. Intellectual Property Rights</h3>
            <h5>a. Ownership</h5>
            <p>
                The Website and all of its content—including text, logos, graphics, images, videos, and software—are
                owned by or licensed to Triton Finance and are protected under{' '}
                <strong>U.S. intellectual property laws</strong>.
            </p>
            <h5>b. Limited License</h5>
            <p>
                ✅ We grant you a <strong>limited, non-exclusive, non-transferable</strong> license to access and use
                the Website <strong>for personal, non-commercial purposes</strong>.
            </p>
            <p className={s.bold}>🚫 You may NOT:</p>
            <ul>
                <li>Copy, modify, distribute, or create derivative works of our content.</li>
                <li>
                    Use our trademarks, service marks, or branding <strong>without written permission</strong>.
                </li>
            </ul>

            <h3 className="h3">5. Privacy Policy & Data Collection</h3>
            <p>🔹 Your use of the Website is also governed by our Privacy Policy, which explains:</p>
            <ul className={s.list} data-list-type="checkboxes">
                <li>What data we collect</li>
                <li>How we use, share, and protect your data</li>
                <li>
                    Your rights under <strong>U.S. privacy laws</strong> (e.g., CCPA, VCDPA)
                </li>
            </ul>
            <p>By using the Website, you consent to our data collection practices.</p>

            <h3 className="h3">6. Disclaimer of Warranties</h3>
            <h5>a. General Disclaimer</h5>
            <ul className={s.list} data-list-type="diamonds">
                <li>The Website and its content are provided on an "as-is" and "as available" basis.</li>
                <li>We do not guarantee the accuracy, completeness, or reliability of any information provided.</li>
            </ul>
            <p className={s.bold}>🚨 YOU USE THE WEBSITE AT YOUR OWN RISK.</p>
            <h5>b. No Financial or Legal Advice</h5>
            <ul className={s.list} data-list-type="checkboxes">
                <li>
                    The information on the Website is for <strong>educational and informational purposes only</strong>.
                </li>
                <li>
                    <strong>Triton Finance does NOT provide legal, financial, or tax advice.</strong>
                </li>
            </ul>
            <p>
                You should consult a licensed <strong>financial advisor or attorney</strong> before making any financial
                decisions.
            </p>

            <h3 className="h3">Governing Law and Jurisdiction</h3>
            <p>
                These Terms shall be governed by and construed in accordance with the laws of [Insert Jurisdiction]. Any
                disputes arising out of or in connection with these Terms or your use of the Website shall be submitted
                to the exclusive jurisdiction of the courts of [Insert Jurisdiction].
            </p>
            <h5>c. Third-Party Content & Services</h5>
            <p>
                We <strong>do not control</strong> or <strong>endorse</strong> third-party content, links, or services
                provided by our debt consolidation or credit repair partners.
            </p>

            <h3>7. Limitation of Liability</h3>
            <p className={s.bold}>🚨 TO THE MAXIMUM EXTENT PERMITTED BY LAW:</p>
            <p>
                ✅ Triton Finance <strong>shall not be liable for</strong>:
            </p>
            <ul>
                <li>
                    <strong>Indirect, incidental, consequential, or punitive damages</strong>, including financial
                    losses.
                </li>
                <li>Any reliance you place on the information on this Website.</li>
                <li>
                    Any <strong>data breaches, cyberattacks, or security incidents</strong> beyond our reasonable
                    control.
                </li>
            </ul>
            <h5>⚠️ MAXIMUM LIABILITY:</h5>
            <p> Our total liability for any claim related to the Website shall not exceed $100 USD.</p>

            <h3>8. Links to Third-Party Websites</h3>
            <ul>
                <li>
                    The Website may contain links to <strong>third-party websites</strong> or services.
                </li>
                <li>
                    We do <strong>not</strong> control these third-party sites and are{' '}
                    <strong>not responsible for their content, terms, or privacy practices</strong>.
                </li>
            </ul>
            <p className={s.bold}>🚨 You access third-party websites at your own risk.</p>

            <h3>9. Termination of Access</h3>
            <p>✅ We reserve the right to suspend or terminate your access to the Website if:</p>
            <ul>
                <li>
                    You <strong>violate these Terms</strong>.
                </li>
                <li>
                    We determine that your actions may <strong>harm Triton Finance, its users, or third parties</strong>
                    .
                </li>
                <li>We decide to discontinue or modify the Website.</li>
            </ul>
            <p>
                🚨 We are <strong>not liable</strong> for any loss or damage caused by account termination.
            </p>

            <h3>10. Governing Law & Dispute Resolution</h3>
            <h5>a. Governing Law</h5>
            <p>
                These Terms are governed by and construed under the laws of the <strong>State of Delaware</strong>,
                without regard to conflict of law principles.
            </p>
            <h5>b. Arbitration Agreement (Mandatory)</h5>
            <p className={s.bold}>
                🚨 By using this Website, you agree that any disputes will be resolved through arbitration.
            </p>
            <p>✅ Dispute Resolution Process:</p>
            <ol className={s.list} data-list-type="numbers">
                <li>
                    <strong>Informal Resolution</strong>: Contact us first at{' '}
                    <a href="mailto:legal@triton.finance">
                        <strong>legal@triton.finance</strong>
                    </a>{' '}
                    to resolve disputes amicably.
                </li>
                <li>
                    <strong>Binding Arbitration</strong>: If unresolved, disputes shall be settled{' '}
                    <strong>exclusively through arbitration</strong>, conducted by the{' '}
                    <strong>American Arbitration Association (AAA)</strong>.
                </li>
                <li>
                    <strong>
                        Class Action Waiver: YOU AGREE TO WAIVE YOUR RIGHT TO PARTICIPATE IN CLASS ACTION LAWSUITS.
                    </strong>
                </li>
            </ol>
            <p className={s.bold}>❌ NO COURT TRIALS OR JURY TRIALS.</p>

            <h3>11. Changes to These Terms</h3>
            <p>We may update these Terms at any time. When we do:</p>
            <ul className={s.list} data-list-type="checkboxes">
                <li>
                    We will update the <strong>Effective Date</strong> at the top of this page.
                </li>
                <li>
                    Continued use of the Website after updates <strong>constitutes your acceptance</strong> of the
                    revised Terms.
                </li>
            </ul>

            <h3 className="h3">12. Contact Us</h3>
            <p>If you have any questions about these Terms, please contact us:</p>
            <br />
            <p>
                📍 <strong>Triton Finance</strong>
                <br />
                <address>3500 S Dupont Hwy, Dover, DE 19901</address>
                📧{' '}
                <a href="mailto:legal@triton.finance">
                    <strong>legal@triton.finance</strong>
                </a>
            </p>
            <ButtonsGroup />
        </div>
    );
};

export default Terms;
