import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import clsx from 'clsx';
import { actionsSelector, contactDetailsSelector, useMedicalDebtQuizStore } from '../../store';
import { ROUTES } from '../../../../constants/constants';
import QuizResultTitle from '../../../Quiz/components/QuizReviewOptionsStep/components/QuizResultTitle';
import Button from '../../../ui/Button';
import IconArrowRight from '../../../ui/Icons/IconArrowRight';
import s from './MedicalDebtQuizSuccessStep.module.css';

const MedicalDebtQuizSuccessStep = () => {
    const navigate = useNavigate();

    const { firstName } = useMedicalDebtQuizStore(useShallow(contactDetailsSelector));
    const { cleanUp } = useMedicalDebtQuizStore(useShallow(actionsSelector));

    const backTooHomeClickHandler = useCallback(() => {
        cleanUp();
        navigate(ROUTES.medicalDebt);
    }, [navigate, cleanUp]);

    return (
        <section className={s.wrap}>
            <QuizResultTitle
                className={s['result-title']}
                title={<>{firstName}, you have been enrolled</>}
                subtitle="Thank you for joining our program! "
                description={
                    <>
                        Our team will be reaching out to you via email with next steps . We look forward to working with
                        you.
                    </>
                }
            />

            <footer className={s.footer}>
                <Button
                    className={clsx(s.cta, s.home)}
                    Icon={IconArrowRight}
                    responsive
                    onClick={backTooHomeClickHandler}
                >
                    Back to home page
                </Button>
            </footer>
        </section>
    );
};

export default memo(MedicalDebtQuizSuccessStep);
