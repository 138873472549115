import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fadeInLeft, fadeInRight } from '../../helpers/utils/animations';
import { subscribeSchema } from '../../helpers/utils/validation';
import { APP_VERSION, SOCIALS } from '../../constants/constants';
import { FOOTER_NAV } from './constants';
import IconLogoDark from '../ui/Icons/IconLogoDark';
import Container from '../Container';
import Button from '../ui/Button';
import Input from '../ui/Input';
import Error from '../ui/Error';
import s from './Footer.module.scss';
import ver from '../../../package.json';

const Footer = () => {
    // const { setMessageModalText, setMessageModalOpen } = useContext(HeaderContext);
    const location = useLocation().pathname.slice(1);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(subscribeSchema),
    });

    const submitHandler = async ({ email }) => {
        console.log(
            `Success! 
                    email: ${email}`
        );
        // try {
        //     const resp = await axios.get(
        //         `https://us-central1-triton-oracle.cloudfunctions.net/emailListSignup?email=${email}`
        //     );
        //     if (resp.status !== 200) {
        //         setMessageModalText('Your request could not be completed. Please try again later.');
        //         setMessageModalOpen(true);
        //     }
        //     setMessageModalText('Thank you for subscribing!');
        //     setMessageModalOpen(true);
        // } catch (e) {
        //     setMessageModalText('Your request could not be completed. Please try again later.');
        //     setMessageModalOpen(true);
        // }
    };

    const footerBg = location === 'policy' || location === 'terms' ? s.grayBg : '';

    return (
        <motion.footer initial="hidden" whileInView="visible" viewport={{ once: true }} className={footerBg}>
            <div className="o-hidden">
                <Container>
                    <div className={s.subscribeBlock}>
                        <div className={s.name}>
                            <motion.h4 custom={1} variants={fadeInLeft}>
                                Newsletter
                            </motion.h4>
                            <motion.p custom={2} variants={fadeInLeft}>
                                Be the first one to know about new articles, updates and special offers
                            </motion.p>
                        </div>
                        <motion.div custom={2} variants={fadeInRight} className={s.formWrap}>
                            <form onSubmit={handleSubmit(submitHandler)} noValidate>
                                <div className={s.formControl}>
                                    <div className={s.input}>
                                        <Controller
                                            control={control}
                                            name="email"
                                            defaultValue=""
                                            render={({ field }) => {
                                                return (
                                                    <Input
                                                        placeholder="Enter your email"
                                                        type="email"
                                                        name="email"
                                                        required={true}
                                                        error={Boolean(errors.email?.message)}
                                                        {...field}
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.email?.message && (
                                            <Error additionalClasses={s.error}>{errors.email?.message}</Error>
                                        )}
                                    </div>
                                    <Button type="submit" variant="primary" className={s.button}>
                                        Subscribe
                                    </Button>
                                </div>
                            </form>
                        </motion.div>
                    </div>
                    <hr />
                    <div className={s.basement}>
                        <div className={s.logo}>
                            <Link to="/">
                                <IconLogoDark width={97} height={38} />
                            </Link>
                            <div>
                                <p>{new Date().getFullYear()}, All Rights Reserved</p>
                                <p className={s.version}>v{ver.version}</p>
                            </div>
                        </div>
                        <div className={s.nav}>
                            {FOOTER_NAV.map((item, i) => (
                                <Link key={i} to={item.to} className={s.text}>
                                    {item.name}
                                </Link>
                            ))}
                        </div>
                        <div className={s.links}>
                            <p className={s.email}>
                                <a href="mailto:hello@triton.finance">hello@triton.finance</a>
                            </p>
                            <div className={s.icons}>
                                {SOCIALS.map((item, i) => {
                                    const { icon: Icon, href } = item;
                                    return (
                                        <a
                                            href={href}
                                            key={i}
                                            target="_blank"
                                            rel="noopener noreferrer nofollow noindex"
                                        >
                                            <Icon />
                                        </a>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </motion.footer>
    );
};

export default Footer;
