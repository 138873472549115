import { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMedicalDebtQuizQualification } from './hooks/useMedicalDebtQuizQualification';
import { ONE_SECOND, ROUTES } from '../../../../constants/constants';
import Typography from '../../../Typography';
import Loader from '../../../ui/loader';
import s from './MedicalDebtQuizReviewOptionsStep.module.css';

const MedicalDebtQuizReviewOptionsStep = () => {
    const navigate = useNavigate();

    const { isQualified } = useMedicalDebtQuizQualification();

    useEffect(() => {
        setTimeout(() => {
            const route = isQualified ? ROUTES.qualified : ROUTES.notQualified;
            navigate(`../${route}`);
        }, 5 * ONE_SECOND);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={s.inner}>
            <Loader />
            <div className={s['title-wrap']}>
                <h2 className="h2">Analyzing the data...</h2>
                <div className={s.subtitle}>
                    <Typography variant="helper-text" asChild>
                        <h5>We prepare options to solve your problem, just a moment...</h5>
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default memo(MedicalDebtQuizReviewOptionsStep);
