import clsx from 'clsx';
import { motion } from 'framer-motion';
import Container from '../Container/Container';
import ButtonFollow from '../ui/ButtonFollow/ButtonFollow';
import styles from './Presentation.module.scss';
import { fadeInLeft, fadeInRight, fadeInUp } from '../../helpers/utils/animations';
import { RESULTS } from './constants';

const Presentation = ({
    executeScroll,
    title = (
        <>
            Triton has been a <strong>trusted provider in debt solutions</strong> for years
        </>
    ),
    results = RESULTS,
    buttonProps,
}) => {
    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={clsx(styles.presentation, 'o-hidden')}
        >
            <Container>
                <div className={styles.holder}>
                    <div className={styles.left}>
                        <motion.h2 custom={1} variants={fadeInLeft}>
                            {title}
                        </motion.h2>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            className={styles.values}
                        >
                            {results.map((item, i) => (
                                <motion.div custom={i + 1} variants={fadeInUp} key={i} className={styles.number}>
                                    <p className={styles.quantity}>
                                        {item.num}
                                        {item.plus && <span>+</span>}
                                        {item.star && (
                                            <span>
                                                <img src="./images/blue-star.svg" alt="star" />
                                            </span>
                                        )}
                                    </p>
                                    <p className={styles.helperText}>{item.text}</p>
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.rightContent}>
                            <motion.h2 custom={1} variants={fadeInRight}>
                                See how Triton can <strong>change your life for the better</strong>
                            </motion.h2>
                            <motion.p custom={2} variants={fadeInRight}>
                                Read a real client's success story after Triton helped them get the financing he needed
                                to overcome his debt
                            </motion.p>
                            <motion.div custom={3} variants={fadeInRight}>
                                <ButtonFollow onClick={executeScroll} reverse {...buttonProps} href="#">
                                    Read Story
                                </ButtonFollow>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </Container>
        </motion.section>
    );
};

export default Presentation;
