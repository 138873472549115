import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useMedicalDebtQuizStepperActiveStepIndex } from './hooks/useMedicalDebtQuizStepperActiveStepIndex';
import { fadeInUp } from '../../../../helpers/utils/animations';
import { MEDICAL_DEBT_QUIZ_STEPS } from './constants';
import Container from '../../../Container';
import QuizStepperProgress from './components/QuizStepperProgress';
import s from './MedicalDebtQuizStepper.module.scss';

const MedicalDebtQuizStepper = () => {
    const activeStepIndex = useMedicalDebtQuizStepperActiveStepIndex();

    const stepsAmount = Object.values(MEDICAL_DEBT_QUIZ_STEPS).reduce((acc, step) => {
        if (step.step > acc) {
            return step.step;
        }

        return acc;
    }, 1);

    return (
        <Container>
            <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} key="stepper-1">
                <motion.div variants={fadeInUp} className={s.wrap}>
                    <div
                        className={clsx(s.top, s.items)}
                        style={{
                            gridTemplateColumns: `repeat(${stepsAmount}, minmax(0, 1fr))`,
                        }}
                    >
                        {Array.from({ length: stepsAmount }, (_, index) => {
                            return (
                                <QuizStepperProgress
                                    key={`stepper-progress-${index}`}
                                    stepIndex={index}
                                    activeStepIndex={activeStepIndex}
                                />
                            );
                        })}
                    </div>
                    <div className={s.holder}>
                        <div className={s.step} key={`step-${activeStepIndex}`}>
                            <strong>Step {activeStepIndex + 1} - </strong>{' '}
                            {Object.values(MEDICAL_DEBT_QUIZ_STEPS)[activeStepIndex].label}
                        </div>
                        <div className={s.text} key={`step-text-${activeStepIndex}`}>
                            {stepsAmount - activeStepIndex - 1} {stepsAmount - activeStepIndex === 1 ? 'step' : 'steps'}{' '}
                            to complete
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        </Container>
    );
};

export default MedicalDebtQuizStepper;
