import { useCallback, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import Card from '../../../../../Card';
import QuizResultTitle from '../../../../../Quiz/components/QuizReviewOptionsStep/components/QuizResultTitle';
import { actionsSelector, personalInfoStepSelector, useCreditRestorationQuizStore } from '../../../../store';
import { useCreditRestorationArrayToHubspot } from '../../hooks/useCreditRestorationArrayToHubspot';
import s from './CreditRestorationQuizSuccess.module.css';
import { CREDIT_RESTORATION_SCORE_AVERAGE_INCREASE } from './constants';
import { getUsersNewPotentialCreditScore } from './utils/getUsersNewPotentialCreditScore';
import Button from '../../../../../ui/Button';
import clsx from 'clsx';
import IconArrowRight from '../../../../../ui/Icons/IconArrowRight';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../../constants/constants';

const CreditRestorationQuizSuccess = () => {
    const { firstName } = useCreditRestorationQuizStore(useShallow(personalInfoStepSelector));
    const quizActions = useCreditRestorationQuizStore(useShallow(actionsSelector));

    const { data: arrayToHubspotResponse } = useCreditRestorationArrayToHubspot();

    const navigate = useNavigate();

    const backToHomePageClickHandler = useCallback(async () => {
        quizActions.cleanUp();

        navigate(ROUTES.home, { replace: true });
    }, [navigate, quizActions]);

    const memoizedScoreData = useMemo(() => {
        if (!arrayToHubspotResponse?.creditScore?.max) {
            return [];
        }

        return [
            {
                label: 'Your Credit Score:',
                value: `${arrayToHubspotResponse?.creditScore?.min ?? 0} - ${arrayToHubspotResponse?.creditScore?.max ?? 0}`,
                type: 'blue',
            },
            {
                label: 'AVG Credit Score Increase:',
                value: `+${CREDIT_RESTORATION_SCORE_AVERAGE_INCREASE}`,
                type: 'green',
            },
            {
                label: 'Your New Potential Credit Score:',
                value: getUsersNewPotentialCreditScore(arrayToHubspotResponse?.creditScore?.max ?? 0),
                type: 'green',
            },
        ];
    }, [arrayToHubspotResponse?.creditScore?.max, arrayToHubspotResponse?.creditScore?.min]);

    return (
        <section className={s.wrap}>
            <QuizResultTitle
                className={s['result-title']}
                title={<>Congratulations {firstName}</>}
                subtitle="You are qualified for UCA's Credit Recovery Program"
                description="A financial consultant from UCA Credit's team will be reaching out shortly."
            />
            <div className={s['score-wrap']}>
                {memoizedScoreData.map(({ type, ...card }) => {
                    return (
                        <Card key={card.label} className={clsx(s.card, s[type])} valueClassName={s.value} {...card} />
                    );
                })}
            </div>
            <footer className={s.footer}>
                <Button
                    className={clsx(s.cta, s.home)}
                    Icon={IconArrowRight}
                    responsive
                    onClick={backToHomePageClickHandler}
                >
                    Back to home page
                </Button>
            </footer>
        </section>
    );
};

export default CreditRestorationQuizSuccess;
