import { memo, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { motion } from 'framer-motion';
import { actionsSelector, cityResidenceSelector, householdMembersSelector, useMedicalDebtQuizStore } from '../../store';
import { fadeInLeft } from '../../../../helpers/utils/animations';
import { ROUTES } from '../../../../constants/constants';
import { HOUSEHOLD_MEMBERS_SELECT_OPTIONS } from '../../constants';
import Container from '../../../Container';
import Typography from '../../../Typography';
import Button from '../../../ui/Button';
import Select from '../../../ui/Select';
import IconArrowRight from '../../../ui/Icons/IconArrowRight';
import s from './MedicalDebtQuizHouseholdMembersStep.module.css';

const MedicalDebtQuizHouseholdMembersStep = () => {
    const navigate = useNavigate();

    const cityResidence = useMedicalDebtQuizStore(useShallow(cityResidenceSelector));
    const householdMembers = useMedicalDebtQuizStore(useShallow(householdMembersSelector));
    const { setField } = useMedicalDebtQuizStore(useShallow(actionsSelector));

    const selectedValue = useMemo(() => {
        if (!householdMembers) {
            return null;
        }

        return HOUSEHOLD_MEMBERS_SELECT_OPTIONS.find((option) => {
            return option.value === householdMembers;
        });
    }, [householdMembers]);

    const prevClickHandler = useCallback(() => {
        navigate(`../${ROUTES.cityResidence}`);
    }, [navigate]);

    const nextClickHandler = useCallback(() => {
        navigate(`../${ROUTES.householdIncome}`);
    }, [navigate]);

    const householdMembersChangeHandler = useCallback(
        (newValue) => {
            setField('householdMembers', newValue);
        },
        [setField]
    );

    useEffect(() => {
        if (!cityResidence) {
            navigate(`../${ROUTES.cityResidence}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityResidence]);

    return (
        <Container>
            <motion.section className={s.wrap} custom={1} variants={fadeInLeft}>
                <Typography className={s.title} variant="heading-h2" asChild>
                    <h3>
                        How many household members, including dependents, were listed on your&nbsp;
                        <strong>last tax return?</strong>
                    </h3>
                </Typography>
                <motion.div className={s['options-wrap']} variants={fadeInLeft}>
                    <Select
                        label="Number of household members/dependents"
                        placeholder="Select an option"
                        options={HOUSEHOLD_MEMBERS_SELECT_OPTIONS}
                        value={selectedValue}
                        isSearchable
                        autoComplete="off"
                        onChange={householdMembersChangeHandler}
                    />
                </motion.div>
                <footer className={s.footer}>
                    <Button className={s.cta} onClick={prevClickHandler} variant="sixth" responsive>
                        Back
                    </Button>
                    <Button
                        className={s.cta}
                        onClick={nextClickHandler}
                        Icon={IconArrowRight}
                        disabled={!householdMembers}
                        responsive
                    >
                        Next
                    </Button>
                </footer>
            </motion.section>
        </Container>
    );
};
export default memo(MedicalDebtQuizHouseholdMembersStep);
