import { useMutation } from '@tanstack/react-query';
import { saveMedicalFile } from '../../api/tritonHubspot';

export const useSaveMedicalFile = () => {
    const mutation = useMutation({
        mutationFn: saveMedicalFile,
    });

    return mutation;
};
