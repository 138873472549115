import { memo, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { PatternFormat } from 'react-number-format';
import { motion } from 'framer-motion';
import { actionsSelector, citizenshipSelector, useMedicalDebtQuizStore } from '../../store';
import { useMedicalDebtQuizPersonalInfoForm } from './hooks/useMedicalDebtQuizPersonalInfoForm';
import { fadeInLeft } from '../../../../helpers/utils/animations';
import { ROUTES } from '../../../../constants/constants';
import Container from '../../../Container';
import Typography from '../../../Typography';
import IconArrowRight from '../../../ui/Icons/IconArrowRight';
import Button from '../../../ui/Button';
import Input from '../../../ui/Input';
import s from './MedicalDebtQuizPersonalInfoStep.module.css';

const MedicalDebtQuizPersonalInfoStep = () => {
    const navigate = useNavigate();

    const citizenship = useMedicalDebtQuizStore(useShallow(citizenshipSelector));
    const quizActions = useMedicalDebtQuizStore(useShallow(actionsSelector));

    const {
        firstNameController: {
            field: firstNameField,
            fieldState: { error: firstNameError },
        },
        lastNameController: {
            field: lastNameField,
            fieldState: { error: lastNameError },
        },
        emailController: {
            field: emailField,
            fieldState: { error: emailError },
        },
        phoneController: {
            field: phoneField,
            fieldState: { error: phoneError },
        },
        handleSubmit,
    } = useMedicalDebtQuizPersonalInfoForm();

    const prevClickHandler = useCallback(() => {
        navigate(`../${ROUTES.citizenship}`);
    }, [navigate]);

    const submitHandler = handleSubmit((data) => {
        quizActions.setFields(data);
        navigate(`../${ROUTES.medicalDebtQuizAddress}`);
    });

    const firstNameChangeHandler = useCallback(
        (event) => {
            firstNameField.onChange(event.target.value);
        },
        [firstNameField]
    );

    const lastNameChangeHandler = useCallback(
        (event) => {
            lastNameField.onChange(event.target.value);
        },
        [lastNameField]
    );

    const emailChangeHandler = useCallback(
        (event) => {
            emailField.onChange(event.target.value);
        },
        [emailField]
    );

    const phoneChangeHandler = useCallback(
        (numberFormatValue) => {
            phoneField.onChange(numberFormatValue.value);
        },
        [phoneField]
    );

    useEffect(() => {
        if (!citizenship) {
            navigate(`../${ROUTES.citizenship}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [citizenship]);

    return (
        <Container>
            <motion.section className={s.wrap} custom={1} variants={fadeInLeft}>
                <Typography className={s.title} variant="heading-h2" asChild>
                    <h3>
                        We value <strong>your privacy</strong>
                    </h3>
                </Typography>
                <h5 className={s.subtitle}>
                    Complete the following questions to verify your identity and access your personalized financing
                    options
                </h5>
                <form onSubmit={submitHandler} noValidate>
                    <motion.div className={s['fields-wrap']} variants={fadeInLeft}>
                        <Input
                            label="First Name"
                            placeholder="Type your first name"
                            required
                            error={!!firstNameError?.message}
                            helperText={firstNameError?.message}
                            {...firstNameField}
                            autoComplete="off"
                            onChange={firstNameChangeHandler}
                        />
                        <Input
                            label="Last Name"
                            placeholder="Type your last name"
                            required
                            error={!!lastNameError?.message}
                            helperText={lastNameError?.message}
                            {...lastNameField}
                            autoComplete="off"
                            onChange={lastNameChangeHandler}
                        />
                        <Input
                            label="Email"
                            placeholder="Type your email"
                            type="email"
                            error={!!emailError?.message}
                            helperText={emailError?.message}
                            required
                            {...emailField}
                            autoComplete="off"
                            onChange={emailChangeHandler}
                        />
                        <PatternFormat
                            format="(###) ###-####"
                            mask="_"
                            type="tel"
                            label="Phone"
                            placeholder="(123) 456-7891"
                            error={!!phoneError?.message}
                            helperText={phoneError?.message}
                            value={phoneField.value}
                            customInput={Input}
                            autoComplete="off"
                            onValueChange={phoneChangeHandler}
                        />
                    </motion.div>
                    <footer className={s.footer}>
                        <Button className={s.cta} type="button" onClick={prevClickHandler} variant="sixth" responsive>
                            Back
                        </Button>
                        <Button className={s.cta} type="submit" Icon={IconArrowRight} responsive>
                            Next
                        </Button>
                    </footer>
                </form>
            </motion.section>
        </Container>
    );
};
export default memo(MedicalDebtQuizPersonalInfoStep);
