import { Navigate, useNavigate } from 'react-router-dom';
import {
    actionsSelector,
    timelineStepSelector,
    understandingNeedsStepSelector,
    useCreditRestorationQuizStore,
} from '../../store';
import { useCallback, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import s from './CreditRestorationQuizTimelineStep.module.css';
import Typography from '../../../Typography';
import { ROUTES } from '../../../../constants/constants';
import RadioButton from '../../../ui/RadioButton';
import Button from '../../../ui/Button';
import IconArrowRight from '../../../ui/Icons/IconArrowRight';
import Container from '../../../Container';
import { motion } from 'framer-motion';
import { fadeInLeft } from '../../../../helpers/utils/animations';
import { CREDIT_RESTORATION_QUIZ_TIMELINE_OPTIONS } from '../../constants';

const CreditRestorationQuizTimelineStep = () => {
    const { creditGoals } = useCreditRestorationQuizStore(useShallow(understandingNeedsStepSelector));
    const { timeline } = useCreditRestorationQuizStore(useShallow(timelineStepSelector));
    const quizActions = useCreditRestorationQuizStore(useShallow(actionsSelector));

    const navigate = useNavigate();

    const optionChangeHandler = useCallback(
        (newValue) => {
            return () => {
                quizActions.setField('timeline', newValue);
            };
        },
        [quizActions]
    );

    const backClickHandler = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        quizActions.setField('currentStepNumber', 3);
    }, [quizActions]);

    if (!creditGoals) {
        return <Navigate to={`/${ROUTES.creditRestorationQuiz}`} replace />;
    }

    return (
        <Container>
            <motion.section className={s.wrap} custom={1} variants={fadeInLeft}>
                <Typography className={s.title} variant="heading-h2" asChild>
                    <h3>
                        How motivated are you to&nbsp;<strong>transform your credit</strong>?
                    </h3>
                </Typography>
                <div className={s['options-wrap']}>
                    {CREDIT_RESTORATION_QUIZ_TIMELINE_OPTIONS.map((option) => {
                        return (
                            <RadioButton
                                key={option}
                                className={s.option}
                                checked={timeline === option}
                                label={option}
                                value={option}
                                onChange={optionChangeHandler(option)}
                            />
                        );
                    })}
                </div>
                <footer className={s.footer}>
                    <Button className={s.cta} variant="sixth" responsive onClick={backClickHandler}>
                        Back
                    </Button>
                    <Button
                        className={s.cta}
                        href={`../${ROUTES.personalInfo}`}
                        disabled={!creditGoals}
                        responsive
                        Icon={IconArrowRight}
                    >
                        Next
                    </Button>
                </footer>
            </motion.section>
        </Container>
    );
};

export default CreditRestorationQuizTimelineStep;
