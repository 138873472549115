import { memo, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { City, State } from 'country-state-city';
import { PatternFormat } from 'react-number-format';
import { motion } from 'framer-motion';
import { actionsSelector, allFieldsSelector, useMedicalDebtQuizStore } from '../../store';
import { useMedicalDebtQuizAddressForm } from './hooks/useMedicalDebtQuizAddressForm';
import { fadeInLeft } from '../../../../helpers/utils/animations';
import {
    BOOLEAN_OPTIONS_OBJECT,
    HUBSPOT_FORMS_GUID,
    HUBSPOT_FORMS_PORTAL_ID,
    ROUTES,
    US_CODE,
} from '../../../../constants/constants';
import Container from '../../../Container';
import Typography from '../../../Typography';
import IconArrowRight from '../../../ui/Icons/IconArrowRight';
import Button from '../../../ui/Button';
import Input from '../../../ui/Input';
import Select from '../../../ui/Select';
import s from './MedicalDebtQuizAddressStep.module.css';
import { useCreateHubspotSubmission } from '../../../../hooks/api/useHubspotApi';

const MedicalDebtQuizAddressStep = () => {
    const navigate = useNavigate();

    const fields = useMedicalDebtQuizStore(useShallow(allFieldsSelector));
    const quizActions = useMedicalDebtQuizStore(useShallow(actionsSelector));

    const { mutate: createHubspotSubmission, isPending: isHubspotSubmissionPending } = useCreateHubspotSubmission();

    const {
        stateController: {
            field: stateField,
            fieldState: { error: stateError },
        },
        cityController: {
            field: cityField,
            fieldState: { error: cityError },
        },
        addressController: {
            field: addressField,
            fieldState: { error: addressError },
        },
        zipController: {
            field: zipField,
            fieldState: { error: zipError },
        },
        handleSubmit,
        setValue,
    } = useMedicalDebtQuizAddressForm();

    const memoizedStatesOptions = useMemo(() => {
        const allStates = State.getStatesOfCountry(US_CODE);

        return allStates.map((state) => {
            return {
                label: state.name,
                value: state.isoCode,
            };
        });
    }, []);

    const memoizedCitiesOptions = useMemo(() => {
        const allCitiesByState = City.getCitiesOfState(US_CODE, stateField.value);

        return allCitiesByState.map((city) => {
            return {
                label: city.name,
                value: city.name,
            };
        });
    }, [stateField.value]);

    const prevClickHandler = useCallback(() => {
        navigate(`../${ROUTES.medicalDebtQuizPersonalInfo}`);
    }, [navigate]);

    const submitHandler = handleSubmit((data) => {
        quizActions.setFields(data);

        const { name: stateNameByCode } = State.getStateByCodeAndCountry(data.state, US_CODE);

        const medicaidServiceField = {
            name: 'did_you_have_medicaid_or_medicare_at_the_time_of_service',
            value: BOOLEAN_OPTIONS_OBJECT[fields.medicaidService].label,
        };
        const cityResidenceField = {
            name: 'have_you_resided_in_the_same_city_for_the_past_year_or_longer',
            value: BOOLEAN_OPTIONS_OBJECT[fields.cityResidence].label,
        };
        const householdMembersField = {
            name: 'how_many_household_members__including_dependents__were_listed_on_your_last_tax_return_',
            value: fields.householdMembers,
        };
        const householdIncomeField = {
            name: 'what_is_your_estimated_household_income',
            value: fields.householdIncome,
        };
        const mortgageField = {
            name: 'do_you_have_a_mortgage',
            value: BOOLEAN_OPTIONS_OBJECT[fields.mortgage].label,
        };
        const deniedMedicAidField = {
            name: 'have_you_applied_for_medicaid_and_been_denied',
            value: BOOLEAN_OPTIONS_OBJECT[fields.deniedMedicAid].label,
        };
        const citizenshipField = {
            name: 'are_you_a_us_citizen',
            value: BOOLEAN_OPTIONS_OBJECT[fields.citizenship].label,
        };
        const firstNameField = {
            name: 'firstname',
            value: fields.firstName,
        };
        const lastNameField = {
            name: 'lastname',
            value: fields.lastName,
        };
        const emailField = {
            name: 'email',
            value: fields.email,
        };
        const phoneField = {
            name: 'phone',
            value: fields.phone,
        };
        const stateField = {
            name: 'state',
            value: stateNameByCode,
        };
        const cityField = {
            name: 'city',
            value: data.city,
        };
        const addressField = {
            name: 'address',
            value: data.address,
        };
        const zipField = {
            name: 'zip',
            value: data.zip,
        };

        createHubspotSubmission(
            {
                formGuid: HUBSPOT_FORMS_GUID,
                portalId: HUBSPOT_FORMS_PORTAL_ID,
                fields: [
                    firstNameField,
                    lastNameField,
                    emailField,
                    phoneField,
                    stateField,
                    cityField,
                    addressField,
                    zipField,
                    medicaidServiceField,
                    cityResidenceField,
                    householdMembersField,
                    householdIncomeField,
                    mortgageField,
                    deniedMedicAidField,
                    citizenshipField,
                ],
            },
            {
                onSuccess() {
                    navigate(`../${ROUTES.billCopy}`);
                },
            }
        );
    });

    const memoizedStateSelectValue = useMemo(() => {
        return memoizedStatesOptions.find((state) => {
            return state.value === stateField.value;
        });
    }, [memoizedStatesOptions, stateField.value]);

    const memoizedCitySelectValue = useMemo(() => {
        if (!cityField.value) {
            return null;
        }

        return memoizedCitiesOptions.find((city) => {
            return city.value === cityField.value;
        });
    }, [memoizedCitiesOptions, cityField.value]);

    const stateFieldChangeHandler = useCallback(
        (stateCode) => {
            stateField.onChange(stateCode);
            cityField.onChange('');
            setValue('city', '');
        },
        [stateField, cityField, setValue]
    );

    const cityFieldChangeHandler = useCallback(
        (cityName) => {
            console.log({ cityName });
            cityField.onChange(cityName);
        },
        [cityField]
    );

    const addressFieldChangeHandler = useCallback(
        (event) => {
            addressField.onChange(event.target.value);
        },
        [addressField]
    );

    const zipFieldChangeHandler = useCallback(
        (numberFormatValue) => {
            zipField.onChange(numberFormatValue.value);
        },
        [zipField]
    );

    useEffect(() => {
        if (!fields.firstName) {
            navigate(`../${ROUTES.medicalDebtQuizPersonalInfo}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields.firstName]);

    return (
        <Container>
            <motion.section className={s.wrap} custom={1} variants={fadeInLeft}>
                <Typography className={s.title} variant="heading-h2" asChild>
                    <h3>
                        We value <strong>your privacy</strong>
                    </h3>
                </Typography>
                <h5 className={s.subtitle}>
                    Complete the following questions to verify your identity and access your personalized financing
                    options
                </h5>
                <form onSubmit={submitHandler} noValidate>
                    <motion.div className={s['fields-wrap']} variants={fadeInLeft}>
                        <Select
                            {...stateField}
                            label="State"
                            placeholder="Choose your state"
                            instanceId={stateField.name}
                            options={memoizedStatesOptions}
                            value={memoizedStateSelectValue}
                            isSearchable
                            error={!!stateError?.message}
                            helperText={stateError?.message}
                            isDisabled={isHubspotSubmissionPending}
                            autoComplete="off"
                            onChange={stateFieldChangeHandler}
                        />
                        <Select
                            {...cityField}
                            label="City"
                            placeholder="Choose your city"
                            instanceId={cityField.name}
                            options={memoizedCitiesOptions}
                            value={memoizedCitySelectValue}
                            isSearchable
                            error={!!cityError?.message}
                            helperText={cityError?.message}
                            isDisabled={isHubspotSubmissionPending || !stateField.value}
                            autoComplete="off"
                            onChange={cityFieldChangeHandler}
                        />
                        <Input
                            {...addressField}
                            label="Street address"
                            placeholder="Type your street address"
                            error={!!addressError?.message}
                            helperText={addressError?.message}
                            disabled={isHubspotSubmissionPending}
                            autoComplete="off"
                            onChange={addressFieldChangeHandler}
                        />
                        <PatternFormat
                            format="#####"
                            mask="_"
                            label="Zip"
                            placeholder="Type your zip code"
                            error={!!zipError?.message}
                            helperText={zipError?.message}
                            value={zipField.value}
                            customInput={Input}
                            disabled={isHubspotSubmissionPending}
                            autoComplete="off"
                            onValueChange={zipFieldChangeHandler}
                        />
                    </motion.div>
                    <footer className={s.footer}>
                        <Button
                            className={s.cta}
                            type="button"
                            disabled={isHubspotSubmissionPending}
                            variant="sixth"
                            responsive
                            onClick={prevClickHandler}
                        >
                            Back
                        </Button>
                        <Button
                            className={s.cta}
                            type="submit"
                            Icon={IconArrowRight}
                            disabled={isHubspotSubmissionPending}
                            responsive
                        >
                            Next
                        </Button>
                    </footer>
                </form>
            </motion.section>
        </Container>
    );
};
export default memo(MedicalDebtQuizAddressStep);
