import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import clsx from 'clsx';
import { actionsSelector, contactDetailsSelector, useMedicalDebtQuizStore } from '../../store';
import { ROUTES } from '../../../../constants/constants';
import QuizResultTitle from '../../../Quiz/components/QuizReviewOptionsStep/components/QuizResultTitle';
import Typography from '../../../Typography';
import Button from '../../../ui/Button';
import IconArrowRight from '../../../ui/Icons/IconArrowRight';
import s from './MedicalDebtQuizNotQualifiedStep.module.css';

const MedicalDebtQuizNotQualifiedStep = () => {
    const navigate = useNavigate();

    const { firstName } = useMedicalDebtQuizStore(useShallow(contactDetailsSelector));
    const { cleanUp } = useMedicalDebtQuizStore(useShallow(actionsSelector));

    const backTooHomeClickHandler = useCallback(() => {
        cleanUp();
        navigate(ROUTES.medicalDebt);
    }, [navigate, cleanUp]);

    return (
        <section className={s.wrap}>
            <QuizResultTitle
                className={s['result-title']}
                title={<>We&apos;ve received your information {firstName}</>}
                subtitle="Thank you for taking the time to reach out to us! "
                description={
                    <>
                        After reviewing the details you've shared, it appears that your demographic factors do not meet
                        the requirements for our Hospital Bill Eraser Program.
                    </>
                }
            />
            <div className={s.description}>
                <Typography variant="button-text" asChild>
                    <p>
                        If you believe that we&apos;ve arrived at this conclusion by mistake, please contact our
                        concierge support at{' '}
                        <a className={clsx(s.link, 'focus-primary')} href="mailto:hello@triton.finance">
                            hello@triton.finance
                        </a>{' '}
                        or{' '}
                        <a className={clsx(s.link, 'focus-primary')} href="tel:+18336112496">
                            (833) 611 2496
                        </a>
                    </p>
                </Typography>
            </div>
            <footer className={s.footer}>
                <Button
                    className={clsx(s.cta, s.home)}
                    Icon={IconArrowRight}
                    responsive
                    onClick={backTooHomeClickHandler}
                >
                    Back to home page
                </Button>
            </footer>
        </section>
    );
};

export default memo(MedicalDebtQuizNotQualifiedStep);
