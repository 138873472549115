import { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useShallow } from 'zustand/react/shallow';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
    actionsSelector,
    configSelector,
    personalInfoStepSelector,
    timelineStepSelector,
    useCreditRestorationQuizStore,
} from '../../store';
import { useCreateCreditRestorationHubspotSubmission } from './hooks/useCreateCreditRestorationHubspotSubmission';
import { fadeInLeft } from '../../../../helpers/utils/animations';
import { ROUTES } from '../../../../constants/constants';
import IconArrowRight from '../../../ui/Icons/IconArrowRight';
import Typography from '../../../Typography';
import Container from '../../../Container';
import Button from '../../../ui/Button';
import QuizPersonalInfoAddressForm from './components/QuizPersonalInfoAddressForm/QuizPersonalInfoAddressForm';
import QuizPersonalInfoNameForm from './components/QuizPersonalInfoNameForm/QuizPersonalInfoNameForm';
import s from './CreditRestorationQuizContactDetailsStep.module.scss';
import 'swiper/scss';
import 'swiper/scss/navigation';

const CreditRestorationQuizContactDetailsStep = () => {
    const { currentStepNumber } = useCreditRestorationQuizStore(useShallow(configSelector));
    const { timeline } = useCreditRestorationQuizStore(useShallow(timelineStepSelector));
    const { isAddressStepValid, isNameDataStepValid } = useCreditRestorationQuizStore(
        useShallow(personalInfoStepSelector)
    );
    const quizActions = useCreditRestorationQuizStore(useShallow(actionsSelector));

    const navigate = useNavigate();

    const { handleCreateHubspotSubmission, isLoading } = useCreateCreditRestorationHubspotSubmission();

    const [swiperRef, setSwiperRef] = useState();

    const isNextButtonEnabled = useMemo(() => {
        const isNameDataStep = currentStepNumber === 4;

        return isNameDataStep ? isNameDataStepValid : isAddressStepValid;
    }, [currentStepNumber, isAddressStepValid, isNameDataStepValid]);

    const prevClickHandler = useCallback(() => {
        if (!swiperRef) {
            return;
        }

        const { isBeginning } = swiperRef;

        if (isBeginning) {
            navigate(-1);
            return;
        }

        swiperRef.slidePrev();

        quizActions.setField('currentStepNumber', 3);
    }, [navigate, quizActions, swiperRef]);

    const nextClickHandler = useCallback(async () => {
        if (!swiperRef) {
            return;
        }

        const { isEnd } = swiperRef;

        if (isEnd) {
            handleCreateHubspotSubmission(() => {
                navigate(`../${ROUTES.reviewOptions}`);
            });

            return;
        }

        swiperRef.slideNext();
        quizActions.setField('currentStepNumber', 5);
        // await createHubspotSubmission();
    }, [handleCreateHubspotSubmission, navigate, quizActions, swiperRef]);

    useEffect(() => {
        quizActions.setField('currentStepNumber', 4);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!timeline) {
        return <Navigate to={`../${ROUTES.timeline}`} replace />;
    }
    return (
        <Container>
            <motion.section className={s.section} custom={1} variants={fadeInLeft}>
                <Typography variant="heading-h2" asChild>
                    <h3>
                        We value <strong>your privacy</strong>
                    </h3>
                </Typography>
                <div className={s.subtitle}>
                    <h4>
                        Complete the following questions to verify your identity and access your personalized financing
                        options
                    </h4>
                </div>
                <div className={s.holder}>
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={20}
                        slidesPerView={1}
                        initialSlide={0}
                        watchOverflow
                        observer
                        observeParents
                        duration={500}
                        observeSlideChildren
                        watchSlidesProgress
                        onSwiper={setSwiperRef}
                        simulateTouch={false}
                    >
                        <SwiperSlide>
                            <QuizPersonalInfoNameForm />
                        </SwiperSlide>
                        <SwiperSlide>
                            <QuizPersonalInfoAddressForm />
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className={s.actions}>
                    <Button onClick={prevClickHandler} variant="sixth" responsive disabled={isLoading}>
                        Back
                    </Button>
                    <Button
                        onClick={nextClickHandler}
                        Icon={IconArrowRight}
                        disabled={!isNextButtonEnabled || isLoading}
                        responsive
                    >
                        Next
                    </Button>
                </div>
            </motion.section>
        </Container>
    );
};

export default CreditRestorationQuizContactDetailsStep;
