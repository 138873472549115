import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import clsx from 'clsx';
import { actionsSelector, useCreditRestorationQuizStore } from '../../../../store';
import { ROUTES } from '../../../../../../constants/constants';
import IconArrowRight from '../../../../../ui/Icons/IconArrowRight';
import Button from '../../../../../ui/Button';
import s from './CreditRestorationQuizError.module.css';

const CreditRestorationQuizError = () => {
    const quizActions = useCreditRestorationQuizStore(useShallow(actionsSelector));

    const navigate = useNavigate();

    const tryAgainClickHandler = useCallback(() => {
        quizActions.cleanUp();
        navigate(`/${ROUTES.creditRestorationQuiz}`);
    }, [navigate, quizActions]);

    return (
        <div className={clsx(s.titleHolder, s.wrap)}>
            <h1 className="h1">
                <strong>Oh no!</strong> Something went wrong
            </h1>
            <div className={s.subtitle}>
                <h5>Try to fill up the form one more time</h5>
            </div>
            <div className={s.action}>
                <Button onClick={tryAgainClickHandler} Icon={IconArrowRight} responsive>
                    Try again
                </Button>
            </div>
        </div>
    );
};

export default memo(CreditRestorationQuizError);
