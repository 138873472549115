import clsx from 'clsx';
import WithHeader from '../../HOC/WithHeader/WithHeader';
import LinkButton from '../LinkButton/LinkButton';
import styles from './TopContentBlock.module.scss';
import Container from '../Container/Container';
import { motion } from 'framer-motion';
import { fadeInLeft, fadeInRight } from '../../helpers/utils/animations';

const TopContentBlock = ({
    titleBold,
    titleRest,
    variant,
    subtitle,
    image,
    button,
    followUs,
    linkTo,
    noHelperText,
    buttonText,
    poweredBy,
    blueBlock,
    imageMob,
    imageClassName,
}) => {
    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={clsx(styles.topContent, styles[variant], 'blueBlock')}
        >
            <WithHeader>
                <div className={clsx(styles.wrap, 'o-hidden')}>
                    <Container>
                        <div className={styles.holder}>
                            <div className={styles.content}>
                                <motion.h1 custom={1} variants={fadeInLeft}>
                                    <strong>{titleBold}</strong> {titleRest}
                                </motion.h1>
                                <motion.p custom={2} variants={fadeInLeft} className={styles.text}>
                                    {subtitle}
                                </motion.p>

                                {button && (
                                    <motion.div custom={3} variants={fadeInLeft}>
                                        <LinkButton
                                            linkTo={linkTo}
                                            text={buttonText}
                                            variant="fourth"
                                            hasIcon
                                            noHelperText={noHelperText ?? true}
                                            blueBlock={blueBlock}
                                        />
                                    </motion.div>
                                )}
                            </div>
                            {/* {followUs && (
                                <div className={styles.findUs}>
                                    <p>As seen in:</p>
                                    <div className={styles.icons}>
                                        <img src="./images/ny.svg" alt="The New Yorker" />
                                        <img src="./images/nyt.svg" alt="The New York Times" />
                                        <img src="./images/bbc.svg" alt="BBC News" />
                                    </div>
                                </div>
                            )} */}
                            {poweredBy && (
                                <div className={styles.poweredBy}>
                                    <p>Powered by:</p>
                                    <div className={styles.icons}>
                                        <img src="./images/svg/uca.svg" alt="UCA" />
                                    </div>
                                </div>
                            )}
                            <div className={styles.image}>
                                {imageMob ? (
                                    <div>
                                        <motion.img
                                            custom={2}
                                            variants={fadeInRight}
                                            src={image}
                                            alt={titleBold}
                                            fetchpriority="high"
                                            className={clsx(imageClassName, styles.desktopImg)}
                                        />
                                        <motion.img
                                            custom={2}
                                            variants={fadeInRight}
                                            src={imageMob}
                                            alt={titleBold}
                                            fetchpriority="high"
                                            className={clsx(imageClassName, styles.mobileImg)}
                                        />
                                    </div>
                                ) : (
                                    <motion.img
                                        custom={2}
                                        variants={fadeInRight}
                                        src={image}
                                        alt={titleBold}
                                        fetchpriority="high"
                                        className={clsx(imageClassName, styles.singleImage)}
                                    />
                                )}
                            </div>
                        </div>
                    </Container>
                </div>
            </WithHeader>
        </motion.div>
    );
};

export default TopContentBlock;
