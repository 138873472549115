export const whyTriton = [
    {
        id: 'w1',
        title: 'Security',
        text: 'Instead of sharing information with multiple lenders, fill out one simple, secure form in five minutes or less.',
        iconDone: true,
    },
    {
        id: 'w2',
        title: 'Savings',
        text: 'We’ll match you with up to five lenders from our network of 300+ lenders who will call to compete for your business.',
        iconDone: true,
    },
    {
        id: 'w3',
        title: 'Support',
        text: 'We provide ongoing support with free credit monitoring, budgeting insights and others',
        iconDone: true,
    },
];
