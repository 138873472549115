import s from './HomePage.module.scss';

export const HOME_PRESENTATION_RESULTS = [
    {
        num: '300',
        plus: true,
        text: 'Satisfied credit repair customers',
    },
    {
        num: '$100M',
        plus: true,
        text: 'Consumer debt resolved',
    },
    {
        num: '$10M',
        plus: true,
        text: 'Consolidation loans matched',
    },
    {
        num: '4.8',
        star: true,
        text: 'Excellent client rating',
    },
];

export const DEBT_CONSOLIDATOR_DOUBLE_SECTION_CONTENT = {
    image: './images/credit-card-skewed.svg',
    imageMob: './images/credit-card-skewed.svg',
    title: 'Debt Consolidation',
    titleBold: 'Consolidation',
    titleRest: 'Debt',
    text: 'Are you struggling with high-interest credit card and personal loan bills? Triton’s debt consolidation partners are here to help. Our network of trusted providers can consolidate your unsecured debts into one manageable monthly payment, easing your financial burden.',
    follow: 'button',
    imagePosition: 'left',
    buttonLabel: 'Start conquering debt',
    innerClassName: s.fullWidth,
};

export const HOME_DOUBLE_SECTIONS_ITEMS = [
    {
        image: './images/hospital.svg',
        imageMob: './images/hospital.svg',
        title: 'Medical Bill Review',
        titleBold: 'Review',
        titleRest: 'Medical Bill',
        text: 'Our providers will review medical bills for accuracy, negotiate claims, and protect you from excessive charges—so you can avoid the stress of collections or credit issues.',
        follow: 'button',
        imagePosition: 'left',
        buttonLabel: 'Get Credit Score',
    },
    {
        image: './images/chart-n-coins.svg',
        imageMob: './images/chart-n-coins.svg',
        title: 'Credit Improvement',
        titleBold: 'Improvement',
        titleRest: 'Credit',
        text: 'If you have a low credit score or negative remarks on your credit report, our credit improvement providers can help remove negative remarks and implement strategies to increase your credit score.',
        follow: 'button',
        imagePosition: 'right',
        buttonLabel: 'Transform my credit',
    },
    DEBT_CONSOLIDATOR_DOUBLE_SECTION_CONTENT,
];

export const HOME_PAGE_REVIEWS = [
    {
        id: 's4',
        rating: 5,
        text: '"Customer service was very responsive and helped me straighten out a problem..."',
        name: 'Marine',
        shortName: 'M',
        date: '3 hours ago',
    },
    {
        id: 's5',
        rating: 5,
        text: '"After many years of paying off credit cards and being extremely tight with money, I was able to consolidate my debts and get a fresh start."',
        name: 'Jack',
        shortName: 'J',
        date: '1 day ago',
        customColor: '#DF8B0E',
        customBg: 'rgba(223, 139, 14, 0.1)',
    },
    {
        id: 's6',
        rating: 5,
        text: 'The team at Triton treated my case with care and urgency. Their expertise in handling disputable items on my report significantly boosted my credit score in just a few months.',
        name: 'Robert',
        shortName: 'R',
        date: '2 hours ago',
        customColor: '#98DB96',
        customBg: 'rgba(152, 219, 150, 0.1)',
    },
];
